import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const PorCom9 = () => {
  const a = useContext(stateContext);

  return (
    <div className="ProfileName m-5 h-full bg-white w-[90%] max-h-[46.5rem] overflow-y-scroll scrollbar-hide shadow-xl py-7">
      <div className="center m-5  ">
        <h1 className="text-medium  text-[0.9rem] font-semibold  ">Title</h1>
        <input
          onChange={a.inputValue}
          name="Por_Title1"
          value={a.newState.Por_Title1}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
        />
        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">Brief</h1>
        <input
          onChange={a.inputValue}
          name="Por_Brief1"
          value={a.newState.Por_Brief1}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />

        <h1 className="text-medium mt-12  text-[0.9rem] font-semibold  ">
          Title
        </h1>
        <input
          onChange={a.inputValue}
          name="Por_Title2"
          value={a.newState.Por_Title2}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
        />
        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">Brief</h1>
        <input
          onChange={a.inputValue}
          name="Por_Brief2"
          value={a.newState.Por_Brief2}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />

       
      </div>
    </div>
  );
};

export default PorCom9;
