import React, { useContext, useState } from "react";
import stateContext from "../Context/State/StateContext";

const TemplateCom1 = () => {
  const a = useContext(stateContext);

  const [addSection, setAddSection] = useState([{ section: "" }]);
  const addButton = () => {
    setAddSection([...addSection, { section: "" }]);
  };
  const sectionRemove = (index) => {
    const section = [...addSection];
    section.splice(index, 1);
    setAddSection(section);
  };

  return (
    <div
      id="DownloadablePage"
      className="MainContainer m-2 pb-1 rounded-sm h-[100%] w-[100%] flex justify-between 
    overflow-y-scroll  scroll-smooth scrollbar-hide "
    >
      <div className={`left w-[40%] sm:w-[35%]  bg-[#325283] text-white `}>
        <div className="mx-5">
          <div className="ProdfilePicture cursor-pointer bg-[#85aeecd3] my-8 mx-auto w-[6rem] h-[6rem] rounded-full flex items-center justify-center sm:w-[7rem] sm:h-[7rem] xl:w-[10rem] xl:h-[10rem]">
            <div className="file text-center space-y-1">
              <label for="files" className="">
                <i class="fa-solid fa-circle-arrow-up text-2xl" id="file"></i>
              </label>
              <p className="text-sm">Upload Photo</p>
              <input id="files" className="hidden" type="file" />
            </div>
          </div>
          {/* cONTENT ONE 1 START */}
          <div className="contentleft1 space-y-2 ">
            <h1 className="contactinfo text-[12px] relative font-bold">
              CONTACT INFO
              <span
                onClick={() => {
                  a.updateState("contact");
                }}
                className={`mx-5
             px-1 text-sm absolute  -right-10 sm:right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             } `}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>

            <div className="contentleft  text-[#cbd3e0] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-white">
                <i class="fa-solid fa-location-dot "></i>{" "}
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <p>{a.newState.C_Location1}</p>
                <p>{a.newState.C_Location2}</p>
              </div>
            </div>

            <div className="contentleft  text-[#cbd3e0] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-white">
                <i class="fa-solid fa-envelope-open"></i>
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <p>{a.newState.C_Email}</p>
              </div>
            </div>

            <div className="contentleft text-[#cbd3e0] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-white">
                <i class="fa-solid fa-phone"></i>
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <p>{a.newState.C_Phone}</p>
              </div>
            </div>

            <div className="contentleft text-[#cbd3e0] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-white">
                <i class="fa-solid fa-link"></i>
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <p>{a.newState.C_Link}</p>
              </div>
            </div>
          </div>
          {/* CONTENT ONE 1 START */}

          {/* CONTENT TWO 2 START */}
          <div className="CONTENT2 my-3 ">
            <h1 className="Skills relative my-2">
              SKILLS SUMMARY
              <span
                onClick={() => {
                  a.updateState("skills");
                }}
                className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             }`}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>
            <div
              className="skillsProgress space-y-2   flex items-center space-x-1 text-[#cbd3e0]
             "
            >
              <span></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level1 === "1" ||
                a.newState.Level1 === "2" ||
                a.newState.Level1 === "3" ||
                a.newState.Level1 === "4"
                  ? "bg-white"
                  : "bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level1 === "2" ||
                a.newState.Level1 === "3" ||
                a.newState.Level1 === "4"
                  ? "bg-white"
                  : " bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level1 === "3" || a.newState.Level1 === "4"
                  ? "bg-white"
                  : " bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${a.newState.Level1 === "4" ? "bg-white" : " bg-gray-500"}`}
              ></span>
              <p className="text-[11px] px-3">{a.newState.Skill1}</p>
            </div>

            <div
              className="skillsProgress space-y-2   flex items-center space-x-1 text-[#cbd3e0]
             "
            >
              <span></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level2 === "1" ||
                a.newState.Level2 === "2" ||
                a.newState.Level2 === "3" ||
                a.newState.Level2 === "4"
                  ? "bg-white"
                  : "bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level2 === "2" ||
                a.newState.Level2 === "3" ||
                a.newState.Level2 === "4"
                  ? "bg-white"
                  : " bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level2 === "3" || a.newState.Level2 === "4"
                  ? "bg-white"
                  : " bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${a.newState.Level2 === "4" ? "bg-white" : " bg-gray-500"}`}
              ></span>
              <p className="text-[11px] px-3">{a.newState.Skill2}</p>
            </div>

            <div
              className="skillsProgress space-y-2   flex items-center space-x-1 text-[#cbd3e0]
             "
            >
              <span></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level3 === "1" ||
                a.newState.Level3 === "2" ||
                a.newState.Level3 === "3" ||
                a.newState.Level3 === "4"
                  ? "bg-white"
                  : "bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level3 === "2" ||
                a.newState.Level3 === "3" ||
                a.newState.Level3 === "4"
                  ? "bg-white"
                  : " bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level3 === "3" || a.newState.Level3 === "4"
                  ? "bg-white"
                  : " bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${a.newState.Level3 === "4" ? "bg-white" : " bg-gray-500"}`}
              ></span>
              <p className="text-[11px] px-3">{a.newState.Skill3}</p>
            </div>

            <div
              className="skillsProgress space-y-2   flex items-center space-x-1 text-[#cbd3e0]
             "
            >
              <span></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level4 === "1" ||
                a.newState.Level4 === "2" ||
                a.newState.Level4 === "3" ||
                a.newState.Level4 === "4"
                  ? "bg-white"
                  : "bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level4 === "2" ||
                a.newState.Level4 === "3" ||
                a.newState.Level4 === "4"
                  ? "bg-white"
                  : " bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level4 === "3" || a.newState.Level4 === "4"
                  ? "bg-white"
                  : " bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${a.newState.Level4 === "4" ? "bg-white" : " bg-gray-500"}`}
              ></span>
              <p className="text-[11px] px-3">{a.newState.Skill4}</p>
            </div>

            <div
              className="skillsProgress space-y-2   flex items-center space-x-1 text-[#cbd3e0]
             "
            >
              <span></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level5 === "1" ||
                a.newState.Level5 === "2" ||
                a.newState.Level5 === "3" ||
                a.newState.Level5 === "4"
                  ? "bg-white"
                  : "bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level5 === "2" ||
                a.newState.Level5 === "3" ||
                a.newState.Level5 === "4"
                  ? "bg-white"
                  : " bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level5 === "3" || a.newState.Level5 === "4"
                  ? "bg-white"
                  : " bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${a.newState.Level5 === "4" ? "bg-white" : " bg-gray-500"}`}
              ></span>
              <p className="text-[11px] px-3">{a.newState.Skill5}</p>
            </div>

            <div
              className="skillsProgress space-y-2   flex items-center space-x-1 text-[#cbd3e0]
             "
            >
              <span></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level6 === "1" ||
                a.newState.Level6 === "2" ||
                a.newState.Level6 === "3" ||
                a.newState.Level6 === "4"
                  ? "bg-white"
                  : "bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level6 === "2" ||
                a.newState.Level6 === "3" ||
                a.newState.Level6 === "4"
                  ? "bg-white"
                  : " bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${
                a.newState.Level6 === "3" || a.newState.Level6 === "4"
                  ? "bg-white"
                  : " bg-gray-500"
              }`}
              ></span>
              <span
                className={`DotLevel p-0.5 rounded-full 
              ${a.newState.Level6 === "4" ? "bg-white" : " bg-gray-500"}`}
              ></span>
              <p className="text-[11px] px-3">{a.newState.Skill6}</p>
            </div>
          </div>
          {/* CONTENT TWO 2 START */}

          {/* CONTENT Three 3 START */}
          <div className="Content3 space-y-2">
            <h1 className="AwardsRecived my-2 relative">
              Awards Recived
              <span
                onClick={() => {
                  a.updateState("award");
                }}
                className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             }`}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>

            <div className="contentleft text-[#cbd3e0] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-white">
                <i class="fa-solid fa-bookmark"></i>
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <p>{a.newState.A_Title1}</p>
              </div>
            </div>

            <div className="contentleft text-[#cbd3e0] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-white">
                <i class="fa-solid fa-bookmark"></i>
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <p> {a.newState.A_Title2} </p>
              </div>
            </div>

            <div className="contentleft text-[#cbd3e0] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-white">
                <i class="fa-solid fa-bookmark"></i>
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <p> {a.newState.A_Title3} </p>
              </div>
            </div>
          </div>
          {/* CONTENT Three 3 START */}
        </div>
      </div>
      <div className="right w-[60%] sm:w-[65%] mx-2 flex flex-col ">
        <div className="contain mx-5 px-2">
          <div
            className="profileName flex flex-col space-y-2
        h-[9rem] my-5  justify-center"
          >
            <h1 className="text-4xl relative my-3 tracking-[1px] font-bold text-[#325283]">
              {a.newState.ProfileName}
              <span
                onClick={() => {
                  a.updateState("profileName");
                }}
                className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             }`}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>
            <span className="w-10 h-[1px] rounded-full  bg-black"></span>
            <h2 className="font-bold text-[14px] text-[#5da9d2]">
              {a.newState.JobRole}
            </h2>
          </div>
          <div className="content1 space-y-2 ">
            <h1 className="text-[#5da9d2] relative text-[12px] font-bold">
              PERSONAL PROFILE
              <span
                onClick={() => {
                  a.updateState("about");
                }}
                className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             }`}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>
            <p className="text-[#7e7e7c] leading-4 text-[11px]">
              {a.newState.ProfileInfo}
            </p>
          </div>

          <div className="content1 space-y-2 my-3 ">
            <h1 className="text-[#5da9d2]  relative text-[12px] font-bold">
              {a.newState.Title}
              <span
                onClick={() => {
                  a.updateState("expriance");
                }}
                className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             }`}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>
            <h1 className="text-[#413f40]  text-[12px] font-bold">
              {a.newState.Title2}
            </h1>
            <p className="t3ext-[#b5b5b5] text-[11px] font-normal leading-4">
              {a.newState.Title3}| {a.newState.DateStart} - {a.newState.EndDate}
            </p>
            <p className="text-[rgb(126,126,124)] leading-4 text-[11px]">
              <li className="text-justify my-2 flex">
                <li></li> <p> {a.newState.Point1}</p>
              </li>
              <li className="text-justify  my-2 flex">
                <li></li>
                <p> {a.newState.Point2}</p>
              </li>
            </p>
          </div>

          <div className="content1 space-y-2 my-3">
            <h1 className="text-[#5da9d2] text-[12px] font-bold relative">
              {a.newState.P_Title}
              <span
                onClick={() => {
                  a.updateState("projects");
                }}
                className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white  text-white ${
               a.RemoveIcons ? "hidden" : ""
             } `}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>
            <p className="text-[#413f40] text-[13px] font-normal leading-4">
              {a.newState.P_Title3}
            </p>
            <p className="text-[#7e7e7c] leading-4 text-[11px]">
              <li className="text-justify my-2 flex">
                <li></li> <p>{a.newState.P_Point1}</p>
              </li>
              <li className="text-justify my-2 flex">
                <li></li>
                <p>{a.newState.P_Point2}</p>
              </li>
            </p>
          </div>

          {addSection.map((index) => (
            <div key={index} className="content1 space-y-2 my-3 ">
              <h1 className="text-[#5da9d2] text-[12px] relative font-bold">
                {a.newState.E_Title}
                <span
                  onClick={() => {
                    a.updateState("education");
                  }}
                  id="editbtn"
                  className={`mx-5
            px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
              a.RemoveIcons ? "hidden" : ""
            }`}
                >
                  <i class="fa-solid fa-pen text-xs"></i>
                </span>
                {addSection.length > 1 && (
                  <span
                    onClick={() => {
                      sectionRemove(index);
                    }}
                    className={`delete mx-5
            px-1 text-sm absolute  right-5 -top-0 rounded-sm bg-[red] border border-white text-white ${
              a.RemoveIcons ? "hidden" : ""
            }`}
                  >
                    <i class="fa-solid fa-trash"></i>
                  </span>
                )}
              </h1>
              <h1 className="text-[#413f40] text-[12px] font-bold">
                {a.newState.E_Title2}
              </h1>
              <p className="text-[#b5b5b5] text-[11px] font-normal leading-4">
                {a.newState.E_Title3} | {a.newState.DateStart} -{" "}
                {a.newState.EndDate}
              </p>
              <p className="text-[#7e7e7c] leading-4 text-[11px]">
                <li className="text-justify my-2 flex">
                  <li></li> <p> {a.newState.E_Point1}</p>
                </li>
                <li className="text-justify my-2 flex">
                  <li></li>
                  <p>{a.newState.E_Point2}</p>
                </li>
                <li className="text-justify my-2 flex">
                  <li></li>
                  <p>{a.newState.E_Point3}</p>
                </li>
              </p>
            </div>
          ))}
          <div
            onClick={() => {
              addButton("1");
            }}
            className={`ADDBUTTON p-2 flex rounded-md font-medium justify-center items-center text-[#8b24f4] border border-[#8b24f4] border-dotted cursor-pointer ${
              a.RemoveIcons ? "hidden" : ""
            } `}
          >
            <i class="fa-solid fa-plus mx-3"></i> Add Section
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateCom1;
