import React from 'react'
import TemplateCom2 from '../Component/TemplateCom2'

const Template2 = () => {
  return (
    <>
    <TemplateCom2/>
    </>
  )
}

export default Template2