import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const TemplateCom7 = () => {
  const a = useContext(stateContext);

  return (
    <div id="DownloadablePage" className="mainContianer ">
      <div className="top flex justify-between items-center my-2">
        <div className="flex flex-col w-[40%]">
          <h1 className="text-[28px]   ml-5 w-full  text-black capitalize font-semibold">
            {`${a.newState.ProfileName}` === "Olivia Wilson" ? (
              <>
                <h1>{a.newState.ProfileName2}</h1>
              </>
            ) : (
              `${a.newState.ProfileName}`
            )}
          </h1>
          <span className="text-xs ml-5"> {a.newState.JobRole}</span>
        </div>
        <div className="colorDiv w-3/5  relative flex h-[25px] mb-3 bg-[#009c6877]">
          <span
            onClick={() => {
              a.updateState("profileName");
            }}
            className={`mx-5
             px-1 text-sm absolute  -right-10 sm:right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             } `}
          >
            <i class="fa-solid fa-pen text-xs"></i>
          </span>
        </div>
      </div>
      <div className="center mx-5">
        <div className="secondDiv my-3 relative text-xs  border-y-2   py-2 border-gray-300">
          <span></span>
          <ul className="ListColorComp7 flex space-x-8 ml-5 relative">
            <li className="ListItems1 "> {a.newState.C_Phone}</li>
            <li className="ListItems2">{a.newState.C_Email}</li>
            <li className="ListItems3">{a.newState. C_Location1}, {a.newState. C_Location2}</li>
            <span
              onClick={() => {
                a.updateState("contact");
              }}
              className={`mx-5
             px-1 text-sm absolute  -right-10 sm:right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             } `}
            >
              <i class="fa-solid fa-pen text-xs"></i>
            </span>
          </ul>
        </div>
        <div className="Profile my-4">
          <h1 className="PROFILE bg-[#009c6877] py-[5px] pl-2 text-[13px] relative font-semibold">
            PROFILE
            <span
              onClick={() => {
                a.updateState("about");
              }}
              className={`mx-5
             px-1 text-sm absolute  -right-10 sm:right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             } `}
            >
              <i class="fa-solid fa-pen text-xs"></i>
            </span>
          </h1>
          <p className="text-[12px] text-[#858585] my-2">
          {a.newState.ProfileInfo}
          </p>
        </div>
        <div className="Education my-2 ">
          <h1 className="Education bg-[#009c6877] py-[5px] pl-2 text-[13px] relative font-semibold">
            Education
            <span
              onClick={() => {
                a.updateState("education");
              }}
              className={`mx-5
             px-1 text-sm absolute  -right-10 sm:right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             } `}
            >
              <i class="fa-solid fa-pen text-xs"></i>
            </span>
          </h1>
          <div className="top flex h-16 items-center ">
            <span
              className="text-xs text-gray-400 mr-5
            "
            >
              {a.newState.E_DateStart} - {a.newState.E_EndDate}
            </span>
            <p className="flex flex-col items-center mx-5 pl-5  border-l-2 border-gray-300">
              <span className="font-meduim text-xs my-1 ">
              {a.newState.E_Title2}
              </span>
              <span className="font-normal text-xs  text-gray-400 ">
              {a.newState.E_Title3}
              </span>
            </p>
          </div>
          <div className="bottom flex h-16 items-center ">
            <span
              className="text-xs  text-gray-400 mr-5
            "
            >
              {a.newState.E_DateStart1} - {a.newState.E_EndDate2}
            </span>
            <p className="flex flex-col items-center mx-5 pl-5  border-l-2 border-gray-300">
              <span className="font-meduim text-xs my-1 ">
              {a.newState.E_Title5}
              </span>
              <span className="font-normal text-xs  text-gray-400 ">
              {a.newState.E_Title4}
              </span>
            </p>
          </div>
        </div>
        <div className="PROJECTS ">
          <h1 className="PROJECTS bg-[#009c6877] py-[5px] pl-2 text-[13px] relative font-semibold capitalize">
            PROJETCS
            <span
              onClick={() => {
                a.updateState("projects3");
              }}
              className={`mx-5
             px-1 text-sm absolute  -right-10 sm:right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             } `}
            >
              <i class="fa-solid fa-pen text-xs"></i>
            </span>
          </h1>
          <div className="top flex   py-3 mt-3 ">
            <span
              className="text-xs mt-1 w-[7.5rem]  text-gray-400 mr-5
            "
            >
              {a.newState.Tem6_DateStart1} - {a.newState.Tem6_DateEnd1}
            </span>
            <p className="flex flex-col pl-5  border-l-2 border-gray-300">
              <span className="font-meduim text-[11px] my-1 ">
                {a.newState.Tem6_Title1}
              </span>
              <span className="font-normal text-[10px] text-gray-400 ">
              {a.newState.Tem6_Title01}
              </span>
              <span className="font-normal text-[10px] text-gray-400 ">
              {a.newState.Tem6_Des1}
              </span>
            </p>
          </div>

          <div className="top flex   py-3 mt-3 ">
            <span
              className="text-xs mt-1 w-[7.5rem]  text-gray-400 mr-5
            "
            >
             {a.newState.Tem6_DateStart2} - {a.newState.Tem6_DateEnd2}
            </span>
            <p className="flex flex-col pl-5  border-l-2 border-gray-300">
              <span className="font-meduim text-[11px] my-1 ">
                {a.newState.Tem6_Title1}
              </span>
              <span className="font-normal text-[10px] text-gray-400 ">
              {a.newState.Tem6_Title02}
              </span>
              <span className="font-normal text-[10px] text-gray-400 ">
              {a.newState.Tem6_Des2}
              </span>
            </p>
          </div>

          <div className="top flex   py-3 mt-3 ">
            <span
              className="text-xs mt-1 w-[7.5rem]  text-gray-400 mr-5
            "
            >
                {a.newState.Tem6_DateStart2} - {a.newState.Tem6_DateEnd2}
            </span>
            <p className="flex flex-col pl-5  border-l-2 border-gray-300">
              <span className="font-meduim text-[11px] my-1 ">
                {a.newState.Tem6_Title3}
              </span>
              <span className="font-normal text-[10px] text-gray-400 ">
              {a.newState.Tem6_Title03}
              </span>
              <span className="font-normal text-[10px] text-gray-400 ">
              {a.newState.Tem6_Des3}
              </span>
            </p>
          </div>
        </div>
        <div className="Education my-2 ">
          <h1 className="Education bg-[#009c6877] py-[5px] pl-2 text-[13px] relative font-semibold uppercase">
            Interships
            <span
              onClick={() => {
                a.updateState("internship");
              }}
              className={`mx-5
             px-1 text-sm absolute  -right-10 sm:right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             } `}
            >
              <i class="fa-solid fa-pen text-xs"></i>
            </span>
          </h1>
          <div className="top flex h-16 items-center ">
            <span
              className="text-xs text-gray-400 mr-5
            "
            >
              {a.newState.I_DateStart} - {a.newState.I_EndDate}
            </span>
            <p className="flex flex-col items-center mx-5 pl-5  border-l-2 border-gray-300">
              <span className="font-meduim text-xs my-1 ">
              {a.newState.I_Title2}
              </span>
              <span className="font-normal text-xs  text-gray-400 ">
              {a.newState.I_Title1}
              </span>
            </p>
          </div>
          <div className="bottom flex h-16 items-center ">
          <span
              className="text-xs text-gray-400 mr-5
            "
            >
              {a.newState.I_DateStart1} - {a.newState.I_EndDate2}
            </span>
            <p className="flex flex-col items-center mx-5 pl-5  border-l-2 border-gray-300">
              <span className="font-meduim text-xs my-1 ">
              {a.newState.I_Title4}
              </span>
              <span className="font-normal text-xs  text-gray-400 ">
              {a.newState.I_Title3}
              </span>
            </p>
          </div>
        </div>
        <div className="Profile ">
          <h1 className="PROFILE bg-[#009c6877] py-[5px] pl-2 text-[13px] relative font-semibold">
            CERTIFICATIONS
            <span
              onClick={() => {
                a.updateState("certification");
              }}
              className={`mx-5
             px-1 text-sm absolute  -right-10 sm:right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             } `}
            >
              <i class="fa-solid fa-pen text-xs"></i>
            </span>
          </h1>
          <ul className="ListColorComp7 text-xs py-3 flex space-x-8 ml-5">
            <li className="ListItems1 ">{a.newState.Cer_title1}</li>
            <li className="ListItems2">{a.newState.Cer_title2}</li>
            <li className="ListItems3">{a.newState.Cer_title3}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TemplateCom7;
