import React, { useContext } from 'react'
import stateContext from "../Context/State/StateContext";


const Interest = () => {
  const a = useContext(stateContext);

  return (
    <div className="ProfileName m-5 h-full bg-white w-[90%] shadow-xl py-7">
    <div className="center m-5  ">
      <h1 className="text-medium  text-[0.9rem] font-semibold  ">Interest / Skills</h1>
      <h1 className="text-medium  text-[0.9rem] font-semibold  ">1.</h1>
      <input
        onChange={a.inputValue}
        name="C4_Title1"
        value={a.newState.C4_Title1}
        className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
        type="text"
        placeholder="C++"
      />

     <h1 className="text-medium  text-[0.9rem] font-semibold  ">2.</h1>
      <input
        onChange={a.inputValue}
        name="C4_Content1"
        value={a.newState.C4_Content1}
        className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
        type="text"
        placeholder="Korian"
      />
     <h1 className="text-medium  text-[0.9rem] font-semibold  ">3.</h1>

     
      <input
        onChange={a.inputValue}
        name="C4_Title3"
        value={a.newState.C4_Title3}
        className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
        type="text"
        placeholder="DataBase"
      />
     
     <h1 className="text-medium  text-[0.9rem] font-semibold  ">4.</h1>

      <input
        onChange={a.inputValue}
        name="C4_Title4"
        value={a.newState.C4_Title4}
        className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
        type="text"
        placeholder="Java"
      />
     
     <h1 className="text-medium  text-[0.9rem] font-semibold  ">5.</h1>
      <input
        onChange={a.inputValue}
        name="C4_Title5"
        value={a.newState.C4_Title5}
        className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
        type="text"
        placeholder="English"
      />
     
    </div>
    <div className="btn flex justify-center items-center p-2">
      <button
        onClick={() => {
          a.updateState("");
        }}
        className="discardbtn text-[#9c50f6] font-semibold "
      >
        Discard
      </button>
    </div>
  </div>
  )
}

export default Interest