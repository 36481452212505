import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const ActivitesCom9 = () => {
  const a = useContext(stateContext);

  return (
    <div className="ProfileName m-5 h-full rounded-lg w-[90%]  shadow-xl py-7">
      <div className="center m-5 ">
        <input
          onChange={a.inputValue}
          name="Activites1"
          value={a.newState.Activites1}
          className="EditProfile my-7 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Olivia Wilson"
        />
        <input
          onChange={a.inputValue}
          name="Activites2"
          value={a.newState.Activites2}
          className="EditProfile my-4 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Olivia Wilson"
        />
      </div>
      <div className="btn flex justify-center items-center p-2">
        <button
          onClick={() => {
            a.updateState("");
          }}
          className="discardbtn text-[#9c50f6] font-semibold "
        >
          Discard
        </button>
      </div>
    </div>
  );
};

export default ActivitesCom9;
