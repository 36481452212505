import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const TemplateCom9 = () => {
  const a = useContext(stateContext);

  return (
    <div id="DownloadablePage" className="MianCOntainer mx-3">
      <div className="flex flex-col w-[40%]">
        <h1 className="text-[21px] relative w-full mt-2 font-bold ml-5  text-black capitalize ">
          {`${a.newState.ProfileName}` === "Olivia Wilson" ? (
            <>
              <h1>{a.newState.ProfileName2}</h1>
            </>
          ) : (
            `${a.newState.ProfileName}`
          )}
          <span
            onClick={() => {
              a.updateState("profileName");
            }}
            className={`mx-5
             px-1 text-sm absolute  -right-10 -sm:right-20 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             } `}
          >
            <i class="fa-solid fa-pen text-xs"></i>
          </span>
        </h1>
      </div>
      <div className="secondDiv my-3 mb-5 mx-3 relative text-xs ">
        <span></span>
        <ul className="ListColorComp7 flex space-x-8 ml-5">
          <li className="ListItems1 ">{a.newState.C_Phone}</li>
          <li className="ListItems2">{a.newState.C_Email}</li>
          <li className="ListItems3">
            {a.newState.C_Location1}, {a.newState.C_Location2}
          </li>
          <li className="ListItems3">{a.newState.C_Link}</li>

          <span
            onClick={() => {
              a.updateState("contact");
            }}
            className={`mx-5
             px-1 text-sm absolute  -right-10 sm:right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             } `}
          >
            <i class="fa-solid fa-pen text-xs"></i>
          </span>
        </ul>
      </div>
      <div className="bottom flex justify-between mt-4 mx-5 ">
        <div className="left w-3/5">
          {/* cONTENT ONE 1 START */}
          <div className="content1 space-y-3 my-2 ">
            <h1 className="text-[#5e1f92] text-[17px] relative font-bold">
              Education
              <span
                onClick={() => {
                  a.updateState("projects3");
                }}
                className="mx-5
           px-1 text-sm absolute -right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-[white]"
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>
            <span className="w-[90%]  flex h-[2px] rounded-full  bg-[#5e1f92]"></span>
            <div className="Content1 space-y-1 ">
              <p className="flex flex-col">
                <span className="text-[black] text-[11px] font-medium   ">
                  {a.newState.Tem6_Title01}
                </span>
                <span className="text-[#5e1f92] text-[14px] font-medium">
                  {a.newState.Tem6_Title1}
                </span>
                <span className="text-[black] text-[11px] font-normal   ">
                  <i
                    style={{ fontSize: "10px" }}
                    class="fa-regular fa-calendar mr-2 text-black"
                  ></i>{" "}
                  {a.newState.Tem6_DateStart1} - {a.newState.Tem6_DateEnd1}
                </span>
              </p>
              <p className="text-[#7e7e7c] -my-1 leading-4 text-[11px]">
                <li className="text-justify  flex">
                  <li></li>
                  <p>{a.newState.Tem6_Des1}</p>
                </li>
              </p>
            </div>
            {/* Second  */}
            <div className="Content2 space-y-1 ">
              <p className="flex flex-col">
                <span className="text-[black] text-[11px] font-medium   ">
                  {a.newState.Tem6_Title02}
                </span>
                <span className="text-[#5e1f92] text-[14px] font-medium">
                  {a.newState.Tem6_Title2}
                </span>
                <span className="text-[black] text-[11px] font-normal   ">
                  <i
                    style={{ fontSize: "10px" }}
                    class="fa-regular fa-calendar mr-2 text-black"
                  ></i>{" "}
                  {a.newState.Tem6_DateStart2} - {a.newState.Tem6_DateEnd2}
                </span>
              </p>
              <p className="text-[#7e7e7c] -my-1 leading-4 text-[11px]">
                <li className="text-justify  flex">
                  <li></li>
                  <p>{a.newState.Tem6_Des2}</p>
                </li>
              </p>
            </div>
            {/* secon End  */}

            {/* Third  */}
            <div className="Content3 space-y-1  ">
              <p className="flex flex-col">
                <span className="text-[black] text-[11px] font-medium   ">
                  {a.newState.Tem6_Title03}
                </span>
                <span className="text-[#5e1f92] text-[14px] font-medium">
                  {a.newState.Tem6_Title3}
                </span>
                <span className="text-[black] text-[11px] font-normal   ">
                  <i
                    style={{ fontSize: "10px" }}
                    class="fa-regular fa-calendar mr-2 text-black"
                  ></i>{" "}
                  {a.newState.Tem6_DateStart3} - {a.newState.Tem6_DateEnd3}
                </span>
              </p>
              <p className="text-[#7e7e7c] -my-1 leading-4 text-[11px]">
                <li className="text-justify  flex">
                  <li></li>
                  <p>{a.newState.Tem6_Des1}</p>
                </li>
              </p>
            </div>
            {/* THird End  */}
          </div>
          {/* CONTENT ONE 1 START */}
          {/* Content2 Start  */}
          <div className="Projects my-3">
            <h1 className="text-[#5e1f92] uppercase text-[17px] relative font-bold">
              Projects
              <span
                onClick={() => {
                  a.updateState("projects4");
                }}
                className="mx-5
           px-1 text-sm absolute -right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-[white]"
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>
            <span className="w-[90%] my-1 flex h-[2px] rounded-full  bg-[#5e1f92]"></span>
            <div className="top my-2">
              <p className=" text-black text-[11px] font-bold">
                {a.newState.P4_Title1}
              </p>
              <p className="text-[10px]">{a.newState.P4_Brief1}</p>
            </div>
          </div>
          <div className="center my-2">
            <p className=" text-black w-[95%] text-[11px] font-bold">
              {a.newState.P4_Title2}
            </p>
            <p className="text-[10px]">{a.newState.P4_Brief2}</p>
          </div>
          <div className="bottom my-3">
            <p className=" text-black text-[11px] font-bold">
              {a.newState.P4_Title3}
            </p>
            <p className="text-[10px]">{a.newState.P4_Brief3}</p>
          </div>
          {/* COntent 2 End  */}
          {/* Content3 Start */}
          <div className="content1  my-3 ">
            <h1 className="text-[#5e1f92] text-[17px] relative font-bold">
              Training
              <span
                onClick={() => {
                  a.updateState("training");
                }}
                className="mx-5
           px-1 text-sm absolute -right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-[white]"
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>
            <span className="w-[90%] my-1 flex h-[2px] rounded-full  bg-[#5e1f92]"></span>
            <div className="Content1 space-y-1 my-4 ">
              <p className="flex flex-col">
                <span className="text-[black] text-[11px] font-medium   ">
                  {a.newState.Traning_T1}
                </span>
                <span className="text-[#5e1f92] text-[14px] font-medium">
                  {a.newState.Company_Name1}
                </span>
                <span className="text-[black] text-[11px] font-normal   ">
                  <i
                    style={{ fontSize: "10px" }}
                    class="fa-regular fa-calendar mr-2 text-black"
                  ></i>{" "}
                  {a.newState.Training_Periad1}
                </span>
              </p>
            </div>
            {/* Second  */}
            <div className="Content2 space-y-1  my-4">
              <p className="flex flex-col">
                <span className="text-[black] text-[11px] font-medium   ">
                  {a.newState.Traning_T2}
                </span>
                <span className="text-[#5e1f92] text-[14px] font-medium">
                  {a.newState.Company_Name2}
                </span>
                <span className="text-[black] text-[11px] font-normal   ">
                  <i
                    style={{ fontSize: "10px" }}
                    class="fa-regular fa-calendar mr-2 text-black"
                  ></i>{" "}
                  {a.newState.Training_Periad2}
                </span>
              </p>
            </div>
            {/* secon End  */}
          </div>
          {/* Content Third End  */}
        </div>
        <div className="right w-2/5">
          <div className="ContentRight1">
            {/* cONTENT ONE 1 START */}
            <div className="content1  my-3 mb-5 ">
              <h1 className="text-[#5e1f92] text-[17px] relative font-bold">
                Skills
                <span
                  onClick={() => {
                    a.updateState("interest");
                  }}
                  className="mx-5
           px-1 text-sm absolute -right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-[white]"
                >
                  <i class="fa-solid fa-pen text-xs"></i>
                </span>
              </h1>
              <span className="w-[90%] my-1 flex h-[2px] rounded-full  bg-[#5e1f92]"></span>
              <div className="Content1 flex-wrap flex space-x-2 space-y-2 items-center">
                <span></span>
                <span className="Skills1 p-1 rounded-md bg-gray-100 text-xs">
                  {a.newState.C4_Title1}
                </span>
                <span className="Skills2 p-1 rounded-md bg-gray-100 text-xs">
                  {a.newState.C4_Content1}
                </span>
                <span className="Skills3 p-1 rounded-md bg-gray-100 text-xs">
                  {a.newState.C4_Title3}
                </span>
                <span className="Skills4 p-1 rounded-md bg-gray-100 text-xs">
                  {a.newState.C4_Title4}
                </span>
                <span className="Skills5 p-1 rounded-md bg-gray-100 text-xs">
                  {a.newState.C4_Title5}
                </span>
              </div>
            </div>
            {/* CONTENT ONE 1 START */}
            {/* cONTENT Two 2 START */}
            <div className="content1 my-4 ">
              <h1 className="text-[#5e1f92] text-[17px] relative font-bold">
                Courses
                <span
                  onClick={() => {
                    a.updateState("course");
                  }}
                  className="mx-5
           px-1 text-sm absolute -right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-[white]"
                >
                  <i class="fa-solid fa-pen text-xs"></i>
                </span>
              </h1>
              <span className="w-[90%] my-1 flex h-[2px] rounded-full  bg-[#5e1f92]"></span>
              <div className="Content1 space-x-2 space-y-2 ">
                <span></span>
                <p className="Skills1 text-xs">
                  <li>{a.newState.Course1}</li>
                </p>
                <p className="Skills2 text-xs">
                  <li>{a.newState.Course2}</li>
                </p>
              </div>
            </div>
            {/* CONTENT Two 2 START */}
            {/* Content 3 start  */}
            <div className="content1 my-6 ">
              <h1 className="text-[#5e1f92] uppercase text-[17px] relative font-bold">
                POR
                <span
                  onClick={() => {
                    a.updateState("por");
                  }}
                  className="mx-5
           px-1 text-sm absolute -right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-[white]"
                >
                  <i class="fa-solid fa-pen text-xs"></i>
                </span>
              </h1>
              <span className="w-[90%] my-1 flex h-[2px] rounded-full  bg-[#5e1f92]"></span>
              <div className="top my-2">
                <p className=" text-black text-[11px] font-bold">
                  {a.newState.Por_Title1}
                </p>
                <p className="text-[10px]">{a.newState.Por_Brief1} </p>
              </div>
              <div className="top my-2">
                <p className=" text-black text-[11px] font-bold">
                  {a.newState.Por_Title2}
                </p>
                <p className="text-[10px]">{a.newState.Por_Brief2}</p>
              </div>
            </div>
            {/* Content 3 End  */}
            {/* Content 4 Start  */}
            <div className="content1 my-5 ">
              <h1 className="text-[#5e1f92] uppercase text-[17px] relative font-bold">
                ACHIEVEMENTS
                <span
                  onClick={() => {
                    a.updateState("achivement");
                  }}
                  className="mx-5
           px-1 text-sm absolute -right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-[white]"
                >
                  <i class="fa-solid fa-pen text-xs"></i>
                </span>
              </h1>
              <span className="w-[90%] my-1 flex h-[2px] rounded-full  bg-[#5e1f92]"></span>
              <div className="Content1 space-x-2 space-y-2 ">
                <span></span>
                <p className="Skills1 text-xs">
                  <li>{a.newState.Achivement1}</li>
                </p>
                <p className="Skills2 text-xs">
                  <li>{a.newState.Achivement2}</li>
                </p>
              </div>
            </div>
            {/* Content 4 End  */}
            {/* Content 5 Start  */}
            <div className="content1 my-5">
              <h1 className="text-[#5e1f92] uppercase text-[17px] relative font-bold">
                Activites
                <span
                  onClick={() => {
                    a.updateState("activites");
                  }}
                  className="mx-5
           px-1 text-sm absolute -right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-[white]"
                >
                  <i class="fa-solid fa-pen text-xs"></i>
                </span>
              </h1>
              <span className="w-[90%] my-1 flex h-[2px] rounded-full  bg-[#5e1f92]"></span>
              <div className="Content1 space-x-2 space-y-2 ">
                <span></span>
                <p className="Skills1 text-xs">
                  <li>{a.newState.Activites1}</li>
                </p>
                <p className="Skills2 text-xs">
                  <li>{a.newState.Activites2}</li>
                </p>
              </div>
            </div>
            {/* Content 5 End  */}
            {/* Content 6 Start  */}
            <div className="content1 my-6 ">
              <h1 className="text-[#5e1f92] uppercase text-[17px] relative font-bold">
                Hobbies
                <span
                  onClick={() => {
                    a.updateState("hobies");
                  }}
                  className="mx-5
           px-1 text-sm absolute -right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-[white]"
                >
                  <i class="fa-solid fa-pen text-xs"></i>
                </span>
              </h1>
              <span className="w-[90%] my-1 flex h-[2px] rounded-full  bg-[#5e1f92]"></span>
              <div className="Content1 space-x-2 space-y-2 ">
                <span></span>
                <p className="Skills1 text-xs">
                  <li>{a.newState.Hobies2}</li>
                </p>
                <p className="Skills2 text-xs">
                  <li>{a.newState.Hobies2}</li>
                </p>
                <p className="Skills2 text-xs">
                  <li>{a.newState.Hobies3}</li>
                </p>
              </div>
            </div>
            {/* Content 6 End // */}
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default TemplateCom9;
