import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const Expriance = () => {
  const a = useContext(stateContext);

  return (
    <div className="ProfileName m-5 h-full bg-white w-[90%] max-h-[46.5rem] overflow-y-scroll  shadow-xl py-7">
      <div className="center m-5  ">
        <h1 className="text-medium  text-[0.9rem] font-semibold  ">Title</h1>
        <input
          onChange={a.inputValue}
          name="Title"
          value={a.newState.Title}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Olivia Wilson"
        />
        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">
          Organisation / University / Project Name
        </h1>
        <input
          onChange={a.inputValue}
          name="Title2"
          value={a.newState.Title2}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />
        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">
          Role / Responsibility / Course
        </h1>
        <input
          onChange={a.inputValue}
          name="Title3"
          value={a.newState.Title3}
          className="EditProfile my-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />

        <h1 className="text-medium my-1 text-[0.9rem] font-semibold ">
          Start Date
        </h1>
        <input
          onChange={a.inputValue}
          name="DateStart"
          value={a.newState.DateStart}
          className="EditProfile my-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />

        <h1 className="text-medium my-1 text-[0.9rem] font-semibold ">
          End Date
        </h1>
        <input
          onChange={a.inputValue}
          name="EndDate"
          value={a.newState.EndDate}
          className="EditProfile mt-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />
        <span className="text-xs mb-2 text-gray-400 font-bold">Leave it blank to mark it as Present</span>

        <h1 className="text-medium mt-2 text-[0.9rem] font-semibold  ">
          Point1
        </h1>
        <textarea
          onChange={a.inputValue}
          name="Point1"
          value={a.newState.Point1}
          className="EditProfile my-4 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Olivia Wilson"
        />
        <h1 className="text-medium  text-[0.9rem] font-semibold  ">Point2</h1>
        <textarea
          onChange={a.inputValue}
          name="Point2"
          value={a.newState.Point2}
          className="EditProfile my-4 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Olivia Wilson"
        />
          <h1 className="text-medium  text-[0.9rem] font-semibold  ">Point3</h1>
        <textarea
          onChange={a.inputValue}
          name="Point3"
          value={a.newState.Point3}
          className="EditProfile my-4 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Olivia Wilson"
        />
        <hr />
        {/* // Second Content Start  */}
        <h1 className="text-medium text-blue-800 my-3 text-[14px] font-semibold  " > Second Content</h1>
      
        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">
          Organisation / University / Project Name
        </h1>
        <input
          onChange={a.inputValue}
          name="Sec_Title2"
          value={a.newState.Sec_Title2}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />
        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">
          Role / Responsibility / Course
        </h1>
        <input
          onChange={a.inputValue}
          name="Sec_Title3"
          value={a.newState.Sec_Title3}
          className="EditProfile my-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />

        <h1 className="text-medium my-1 text-[0.9rem] font-semibold ">
          Start Date
        </h1>
        <input
          onChange={a.inputValue}
          name="Sec_DateStart"
          value={a.newState.Sec_DateStart}
          className="EditProfile my-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />

        <h1 className="text-medium my-1 text-[0.9rem] font-semibold ">
          End Date
        </h1>
        <input
          onChange={a.inputValue}
          name="Sec_EndDate"
          value={a.newState.Sec_EndDate}
          className="EditProfile mt-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />
        <span className="text-xs mb-2 text-gray-400 font-bold">Leave it blank to mark it as Present</span>

        <h1 className="text-medium mt-2 text-[0.9rem] font-semibold  ">
          Point1
        </h1>
        <textarea
          onChange={a.inputValue}
          name="Sec_Point1"
          value={a.newState.Sec_Point1}
          className="EditProfile my-4 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Olivia Wilson"
        />
        <h1 className="text-medium  text-[0.9rem] font-semibold  ">Point2</h1>
        <textarea
          onChange={a.inputValue}
          name="Sec_Point2"
          value={a.newState.Sec_Point2}
          className="EditProfile my-4 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Olivia Wilson"
        />
        {/* Second Content End  */}
      </div>
      {/* Add And Delete BLockBUtton Start  */}
      <div className="DeleteBlock p-1 my-3 mb-7 flex rounded-md justify-center items-center text-[red] text-sm border-2 w-[95%] mx-auto  border-[red] border-dotted">
         Delete Block
      </div>
      <div className="ADDBLOCK p-1 text-sm my-3 flex rounded-md justify-center items-center text-[#8b24f4] border-2 w-[95%] mx-auto border-[#8b24f4] border-dotted">
        <i class="fa-solid fa-plus mx-3"></i> Add Block
      </div>
      {/* Add And Delete BLockBUtton End  */}

      <div className="btn flex justify-center items-center p-2">
        <button
          onClick={() => {
            a.updateState("");
          }}
          className="discardbtn text-[#9c50f6] font-semibold "
        >
          Discard
        </button>
      </div>
    </div>
  );
};

export default Expriance;
