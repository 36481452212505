import React, { useContext } from 'react'
import stateContext from "../Context/State/StateContext";

const CertificationCom7 = () => {
    const a = useContext(stateContext);

  return (
    <div className="ProfileName m-5 h-full bg-white w-[90%] shadow-xl py-7">
    <div className="center m-5 space-y-5 ">
        <input
        onChange={a.inputValue}
        name="Cer_title1"
        value={a.newState.Cer_title1}
        className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
        type="text"
        placeholder="Introduction to MySQL"
      />

      <input
        onChange={a.inputValue}
        name="Cer_title2"
        value={a.newState.Cer_title2}
        className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
        type="text"
        placeholder="Basic Web Developer"
      />

     
      <input
        onChange={a.inputValue}
        name="Cer_title3"
        value={a.newState.Cer_title3}
        className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
        type="text"
        placeholder="CCP - AWSCCP"
      />
    </div>
    </div>
  )
}

export default CertificationCom7