const render = [
  { Template: "/templates1", Resume: "img/ThumbnailRes1.jpg" },
  { Template: "/templates2", Resume: "img/ThumbnailRes2.jpg" },
  { Template: "/templates3", Resume: "img/ThumbnailRes3.jpg" },
  { Template: "/templates4" , Resume: "img/ThumbnailRes4.jpg" },
  { Template: "/templates5" , Resume: "img/ThumbnailRes5.jpg" },
  { Template: "/templates6" , Resume: "img/ThumbnailRes6.jpg" },
  { Template: "/templates7" , Resume: "img/ThumbnailRes7.jpg" },
  { Template: "/templates8" , Resume: "img/ThumbnailRes8.jpg" },
  { Template: "/templates9" , Resume: "img/ThumbnailRes9.jpg" },



];
export default render;
