import "./App.css";
import Navbar from "./Component/Navbar";
import Home from "./Component/Home";
import Footer from "./Component/Footer";
import Templates from "./pages/Templates";
import Template1 from "./pages/Template1";
import Template2 from "./pages/Template2";
import Template3 from "./pages/Template3";
import TemplateCom4 from "./Component/TemplateCom4";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ChangeState from "./Context/State/ChangeState";
import TempEdite from "./Component/TempEdite";

function App() {
  return (
    <>
      <ChangeState>
        <Router>
          <Switch>
            <Route exact path="/">
              <Navbar />
              <Home />
            </Route>
        
            <Route exact path="/templates1">
              <TempEdite comp="templates1" />
            </Route>
            <Route exact path="/templates2">
              <TempEdite comp="templates2"/>
            </Route>
            <Route exact path="/templates3">
              <TempEdite comp="templates3"/>
            </Route>
            <Route exact path="/templates4">
              <TempEdite comp="templates4" size="2/6"/>
            </Route>
            <Route exact path="/templates5">
              <TempEdite comp="templates5"/>
            </Route>
            <Route exact path="/templates6">
              <TempEdite comp="templates6"/>
            </Route>
            <Route exact path="/templates7">
              <TempEdite comp="templates7"/>
            </Route>
            <Route exact path="/templates8">
              <TempEdite comp="templates8"/>
            </Route>
            <Route exact path="/templates9">
              <TempEdite comp="templates9"/>
            </Route>
          </Switch>
          <Footer />
        </Router>
      </ChangeState>
    </>
  );
}

export default App;
