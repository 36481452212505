import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const TempNavbar = () => {
  const a = useContext(stateContext);


  return (
    <>
      <div className="MainContainer break-words py-6 bg-[#5f0aa8] flex flex-col justify-between items-center space-y-4 sm:space-y-0 sm:flex-row">
        <div className="left">
          <a href="/">
            <div
              className="font-extrabold text-2xl cursor-pointer flex items-center font-heading
      text-[white] md:text-3xl mx-4 "
            >
              Cwpedia | Resume Builder
            </div>
          </a>
        </div>
        <div className="right  flex justify-center items-center">
          <div onClick={a.reset} className="btn1 font-medium cursor-pointer mx-1 ml-5 p-0.5 px-4 rounded-sm text-white border-2 border-white">
            Reset
          </div>
          <div onClick={()=>{a.downloadResume("DownloadablePage","true")}} className="btn2 p-1  font-bold cursor-pointer px-5 rounded-sm mx-5 bg-white text-center text-purple-700">
            Download
          </div>
        </div>
      </div>
    </>
  );
};

export default TempNavbar;
