import React from "react";
import bg from "../img/bg.webp";
import bg2 from "../img/bg2.webp";
import bg1 from "../img/bg1.webp";
import logo from "../img/logo.png"
import Render from "../RenderData";

const Home = () => {
  return (
    <>
      <div className="ContainerMain z-10 relative top-1 font-head py-2 break-words ">

        <div className="middleDiv   relative mx-5  my-32">

          <h2
              id="heading"
              className="font-bold mx-1 text-black
            text-4xl font-head text-center my-2  md:mx-12 md:text-5xl
            "
          >
            Free Resume Builder by


          </h2>

          <div className="mt-10 flex justify-center items-center flex-col">
            <img

                src={logo}
                title="CWIPEDIA"
                className="d-flex justify-content-center img-fluid logo flex"
            />
            <h2 className="text-center mx-5 text-3xl w-full font-semibold">
              Resume Templates
            </h2>
            <p className="des text-center mx-5 text-[#8168FA] md:w-1/2 my-3 leading-8">

                Welcome to Resume Builder where you can pick any template you want and edit.

            </p>
          </div>
        </div>
        <div className="cardsTemplates sm:space-x-7 space-y-3 flex flex-wrap my-8 justify-center items-center md:w-11/12 ">
          <div className="hidden"></div>
          {Render.map((item) => (
            <div
              id="cardTemplate"
              className="carditem rounded-md relative flex justify-center items-end h-96 w-72 hover:bg-[#ffffffb3]
              transition-all duration-500 border-[1px]"
            >
              <img

                src={item.Resume}
                alt={item.Resume}
                className="img w-ful z-[-1] absolute h-full object-cover"
              />
              <a
                href={item.Template}
                //  href="/templates"
              >
                <button
                  id="btn"
                  className="btn transition-all duration-500 p-1 px-4 mb-8 bg-gradient-to-r from-[#8a23f1] to-[#630cae] rounded-sm font-bold text-white text-sm"
                >
                  Select
                </button>
              </a>
            </div>
          ))}
          {/* <div
            id="cardTemplate"
            className="carditem rounded-md relative flex justify-center items-end h-96 w-72 hover:bg-[#ffffffb3]
          transition-all duration-500 border-[1px]"
          >
            <img
              src={re}
              alt=""
              className="w-ful z-[-1] absolute h-full object-cover"
            />
            <a href="/templates">
              <button
                id="btn"
                className="btn transition-all duration-500 p-1 px-4 mb-8 bg-gradient-to-r from-[#8a23f1] to-[#630cae] rounded-sm font-bold text-white text-sm"
              >
                Select
              </button>
            </a>
          </div> */}

          {/* <div
            id="cardTemplate"
            className="carditem rounded-md relative flex justify-center items-end h-96 w-72 hover:bg-[#ffffffb3]
          transition-all duration-500 border-[1px]"
          >
            <img
              src={re}
              alt=""
              className="w-ful z-[-1] absolute h-full object-cover"
            />
            <a href="/templates">
            <button
              id="btn"
              className="btn transition-all duration-500 p-1 px-4 mb-8 bg-gradient-to-r from-[#8a23f1] to-[#630cae] rounded-sm font-bold text-white text-sm"
            >
              Select
            </button>
            </a>
          </div>

          <div
            id="cardTemplate"
            className="carditem rounded-md relative flex justify-center items-end h-96 w-72 hover:bg-[#ffffffb3]
          transition-all duration-500 border-[1px]"
          >
            <img
              src={re}
              alt=""
              className="w-ful z-[-1] absolute h-full object-cover"
            />
            <a href="/templates">
            <button
              id="btn"
              className="btn transition-all duration-500 p-1 px-4 mb-8 bg-gradient-to-r from-[#8a23f1] to-[#630cae] rounded-sm font-bold text-white text-sm"
            >
              Select
            </button>
            </a>
          </div>

          <div
            id="cardTemplate"
            className="carditem rounded-md relative flex justify-center items-end h-96 w-72 hover:bg-[#ffffffb3]
          transition-all duration-500 border-[1px]"
          >
            <img
              src={re}
              alt=""
              className="w-ful z-[-1] absolute h-full object-cover"
            />
            <a href="/templates">
            <button
              id="btn"
              className="btn transition-all duration-500 p-1 px-4 mb-8 bg-gradient-to-r from-[#8a23f1] to-[#630cae] rounded-sm font-bold text-white text-sm"
            >
              Select
            </button>
            </a>
          </div>

          <div
            id="cardTemplate"
            className="carditem rounded-md relative flex justify-center items-end h-96 w-72 hover:bg-[#ffffffb3]
          transition-all duration-500 border-[1px]"
          >
            <img
              src={re}
              alt=""
              className="w-ful z-[-1] absolute h-full object-cover"
            />
            <a href="/templates">
            <button
              id="btn"
              className="btn transition-all duration-500 p-1 px-4 mb-8 bg-gradient-to-r from-[#8a23f1] to-[#630cae] rounded-sm font-bold text-white text-sm"
            >
              Select
            </button>
            </a>
          </div>

          <div
            id="cardTemplate"
            className="carditem rounded-md relative flex justify-center items-end h-96 w-72 hover:bg-[#ffffffb3]
          transition-all duration-500 border-[1px]"
          >
            <img
              src={re}
              alt=""
              className="w-ful z-[-1] absolute h-full object-cover"
            />
            <a href="/templates">
            <button
              id="btn"
              className="btn transition-all duration-500 p-1 px-4 mb-8 bg-gradient-to-r from-[#8a23f1] to-[#630cae] rounded-sm font-bold text-white text-sm"
            >
              Select
            </button>
            </a>
          </div>

          <div
            id="cardTemplate"
            className="carditem rounded-md relative flex justify-center items-end h-96 w-72 hover:bg-[#ffffffb3]
          transition-all duration-500 border-[1px]"
          >
            <img
              src={re}
              alt=""
              className="w-ful z-[-1] absolute h-full object-cover"
            />
            <a href="/templates">
            <button
              id="btn"
              className="btn transition-all duration-500 p-1 px-4 mb-8 bg-gradient-to-r from-[#8a23f1] to-[#630cae] rounded-sm font-bold text-white text-sm"
            >
              Select
            </button>
            </a>
          </div>

          <div
            id="cardTemplate"
            className="carditem rounded-md relative flex justify-center items-end h-96 w-72 hover:bg-[#ffffffb3]
          transition-all duration-500 border-[1px]"
          >
            <img
              src={re}
              alt=""
              className="w-ful z-[-1] absolute h-full object-cover"
            />
            <a href="/templates">
            <button
              id="btn"
              className="btn transition-all duration-500 p-1 px-4 mb-8 bg-gradient-to-r from-[#8a23f1] to-[#630cae] rounded-sm font-bold text-white text-sm"
            >
              Select
            </button>
            </a>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Home;
