import React from "react";
import footer from "../img/Footer.png";
import logo from "../img/logo.png"
const Footer = () => {
  return (

      <>
          <p className="h4 text-center">Resume Builder by</p>
      <div className="d-flex justify-content-center">

          <img className="img-fluid logo1" title="cwipedia" src={logo} />

      </div>
      </>
  );
};

export default Footer;
