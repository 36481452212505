import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const TemplateCom4 = () => {
  const a = useContext(stateContext);

  return (
    <div id="DownloadablePage" className="MoaiContainer  w-[100%] mx-auto">
      <div className="Content w-[96%] py-2 mx-auto">
        <h1 className="text-2xl relative my-3 tracking-[1px] font-bold text-[black]">
          {/* {a.newState.ProfileName} */}
          Aves Solanki
          <span
            onClick={() => {
              a.updateState("profileName");
            }}
            className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             }`}
          >
            <i class="fa-solid fa-pen text-xs"></i>
          </span>
        </h1>
        <h2 className="font-medium text-[12px] text-[#5da9d2]">
          {/* {a.newState.JobRole} */}
          <a href="#" className="underline ">
            AvesSolanki@gmail.com
          </a>
        </h2>
        <div className="Links flex my-2 space-x-3 items-center  text-[14px] font-medium">
          <div className="links1">
            Project:{" "}
            <a className="text-[13px] underline" href="#">
              github.com/AvesSolanki
            </a>
          </div>
          <div className="links2">
            Portfolio:
            <a className="text-[13px] underline" href="#">
              Shivamh.github.i
            </a>{" "}
          </div>
          <div className="links3">
            Hackerank:{" "}
            <a className="text-[13px] underline" href="#">
              ShivamH
            </a>{" "}
          </div>
          <div className="links4">
            <a className="text-[13px] underline" href="#">
              Linkedin.com/in/shivam
            </a>{" "}
          </div>
        </div>
        <div className="Education">
          <h1 className="text-xl relative my-3 tracking-[1px] font-medium text-[black]">
            <span
              onClick={() => {
                a.updateState("education");
              }}
              className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             }`}
            >
              <i class="fa-solid fa-pen text-xs"></i>
            </span>
            Education
            {/* {a.newState.E_Title} */}
          </h1>
          <hr className="hrTag mb-2 bg-black w-[98%] mx-auto" />
          <div className="E_content flex justify-between items-center ">
            <span className="text-[13px] font-medium">
              Matsyodari Shikshan Sanstha's College Of Engineering & Technology,
              Jalna
              {/* {a.newState.E_Title2} */}
            </span>
            <span className="text-[14px] font-medium">
              2017-2020
              {/* {a.newState.E_DateStart} - {a.newState.E_EndDate}   */}
            </span>
          </div>
          <span className="text-[#adadad] text-[13px] font-normal ">
            Diploma in Computer Science & Engineering
            {/* {a.newState.E_Title3}  */}
          </span>

          <div className="E_content2 mt-3 flex justify-between items-center ">
            <span className="text-[14px] font-medium">
              Matsyodari Shikshan Sanstha's College Of Engineering & Technology,
              Jalna
              {/* {a.newState.E_Title4} */}
            </span>
            <span className="text-[14px] font-medium">
              2017-2020
              {/* {a.newState.E_DateStart1} - {a.newState.E_EndDate2} */}
            </span>
          </div>
          <span className="text-[#adadad] text-[14px] font-normal ">
            Diploma in Computer Science & Engineering
            {/* {a.newState.E_Title5} */}
          </span>

          {/* //Skills Start  */}
          <div className="Skills">
            <h1 className="text-xl relative my-3 tracking-[1px] font-medium text-[black]">
              <span
                onClick={() => {
                  a.updateState("skillsCom4");
                }}
                className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             }`}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
              Skills
            </h1>
            <hr className="hrTag mb-2 bg-black w-[98%] mx-auto" />
            <div className="SkillsDiv w-[90%] mx-auto">
              <div className="SkillsContent  flex justify-between items-center">
                <li className="SKills font-bold  text-[14px]">
                  {a.newState.C4_Title}
                </li>
                <span className="font-medium  w-[43%] text-left text-[13px] ">
                  {a.newState.C4_Content1}
                </span>
              </div>
              <div className="SkillsContent flex justify-between items-center">
                <li className="SKills font-bold text-[13px]">
                  {a.newState.C4_Title2}{" "}
                </li>
                <span className="font-medium  w-[43%] text-left text-[13px]">
                  {a.newState.C4_Content2}
                </span>
              </div>
              <div className="SkillsContent flex justify-between items-center">
                <li className="SKills font-bold text-[13px]">
                  {a.newState.C4_Title3}{" "}
                </li>
                <span className="font-medium  w-[43%] text-left text-[13px]">
                  {a.newState.C4_Content3}
                </span>
              </div>
              <div className="SkillsContent flex justify-between items-center">
                <li className="SKills font-bold text-[13px]">
                  {a.newState.C4_Title4}{" "}
                </li>
                <span className="font-medium  w-[43%] text-left text-[13px]">
                  {a.newState.C4_Content4}
                </span>
              </div>
              <div className="SkillsContent flex justify-between items-center">
                <li className="SKills font-bold text-[13px]">
                  {a.newState.C4_Title5}
                </li>
                <span className="font-medium  w-[43%] text-left text-[13px]">
                  {a.newState.C4_Content5}
                </span>
              </div>
            </div>
          </div>
          {/* //Skills End  */}
          {/* WorkExpriance Start  */}
          <div className="WorkExpriance">
            <h1 className="text-lg relative my-3 tracking-[1px] font-medium text-[black]">
              <span
                onClick={() => {
                  a.updateState("Work1");
                }}
                className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             }`}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
              WORK EXPERIENCE & OPEN SOURCE CONTRIBUTIONS
            </h1>
            <hr className="hrTag mb-2 bg-black w-[98%] mx-auto" />
            <div className="E_content flex justify-between  items-center ">
              <span className="text-[14px] font-normal">
                <span className="font-bold mx-1">{a.newState.C4W_Title}</span>
                {a.newState.C4W_Content1}
              </span>
              <span className="text-[14px] font-medium">2017-2020</span>
            </div>
            <li className="text-[14px] font-medium mx-5">
              {a.newState.C4W_Point1}
            </li>
            <div className="W_Content2">
              <div className="E_content flex justify-between  items-center ">
                <span className="text-[14px] font-medium">
                  <span className="font-bold text-[15px]">
                    {a.newState.C4W_Title2}
                  </span>
                  {a.newState.C4W_Content2}(
                  <a className="underline text-blue-400" href="#">
                    Badge
                  </a>
                  )
                </span>
                <span className="text-[14px] font-medium">2017-2020</span>
              </div>
              <li className="text-[14px] font-medium mx-5">
                {a.newState.C4W_Point2}
              </li>
              <li className="text-[14px] font-medium mx-5">
                {a.newState.C4W_Point3}
              </li>
              <div className="E_content flex justify-between  items-center ">
                <span className="text-[14px] font-medium">
                  <span className="font-bold text-[13px]">
                    {a.newState.C4W_Title3}
                  </span>
                  - {a.newState.C4W_Content3}
                </span>
                <span className="text-[14px] font-medium">2017-2020</span>
              </div>
              <li className="text-[14px] font-medium mx-5">
                {a.newState.C4W_Point4}
              </li>
              <li className="text-[14px] font-medium mx-5">
                {a.newState.C4W_Point5}
              </li>
            </div>
          </div>
          {/* WorkExpriance End  */}
          {/* VOLUNTEERING EXPERIENCE Start */}
          <div
            className="WorkExpriance End
           VOLUNTEERING EXPERIENCE mt-2"
          >
            <h1 className="text-lg relative my-1 tracking-[1px] font-medium text-[black]">
              <span
                onClick={() => {
                  a.updateState("expriance2");
                }}
                className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             }`}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
              VOLUNTEERING EXPERIENCE
            </h1>
            <hr className="hrTag mb-2 bg-black w-[98%] mx-auto" />
            <li className="text-[14px] font-medium mx-5">
              {a.newState.C4E_Point1}
              <a className="underline text-blue-400" href="#">
                cwpidia
              </a>
            </li>
            <li className="text-[14px] font-medium mx-5">
              {a.newState.C4E_Point2}
            </li>
          </div>
          {/* VOLUNTEERING EXPERIENCE End */}

          {/* PROJECTS Start */}
          <div
            className="WorkExpriance End 
         PROJECTS"
          >
            <h1 className="text-lg relative my-1 tracking-[1px] font-medium text-[black]">
              <span
                onClick={() => {
                  a.updateState("projects2");
                }}
                className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white  text-white ${
               a.RemoveIcons ? "hidden" : ""
             } `}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
              PROJECTS
            </h1>
            <hr className="hrTag mb-2 bg-black w-[98%] mx-auto" />
            <li className="text-[14px] font-medium mx-5">
              {a.newState.P2_Point1}
            </li>
            <li className="text-[14px] font-medium mx-5">
              {a.newState.P2_Point2}
            </li>
            <li className="text-[14px] font-medium mx-5">
              {a.newState.P2_Point3}
            </li>
            <li className="text-[14px] font-medium mx-5">
              {a.newState.P2_Point4}
            </li>
          </div>
          {/* PROJECTS End */}

          {/* .Ceertification Start */}
          <div className="Certification">
            <h1 className="text-lg relative my-3 tracking-[1px] font-medium text-[black]">
            <span
                onClick={() => {
                  a.updateState("Certification");
                }}
                className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white  text-white ${
               a.RemoveIcons ? "hidden" : ""
             } `}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
              CERTIFICATIONS, ACHIEVEMENT & AWARDS
            </h1>
            <hr className="hrTag mb-2 bg-black w-[98%] mx-auto" />
            <div className="E_content flex justify-between  items-center ">
              <span className="text-[14px] font-medium">
                <span className="font-bold">
                  {a.newState.C4C_Point1}:-
                </span>
                {a.newState.C4C_Point2}
              </span>
              {/* <span className="text-[14px] font-medium">2017-2020</span> */}
            </div>
            <li className="text-[14px] font-medium mx-5">
            {a.newState.C4C_Point3}
            </li>

            <div className="E_content flex justify-between  items-center ">
              <span className="text-[14px] font-bold">
              {a.newState.C4C_Point4}
              </span>
              {/* <span className="text-[14px] font-medium">2017-2020</span> */}
            </div>
            <li className="text-[14px] font-medium mx-5">
            {a.newState.C4C_Point5}
            </li>
            <div className="lastCOntent">
              <span className="text-[14px] font-bold">
              {a.newState.C4C_Point6}:-
                <span className="text-[14px] font-medium">
                {a.newState.C4C_Point7}
                </span>
              </span>
            </div>
          </div>
          {/* .Ceertification Start */}
        </div>
      </div>
    </div>
  );
};

export default TemplateCom4;
