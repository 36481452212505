import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const TemplateCom2 = () => {
  const a = useContext(stateContext);

  return (
    <div
    id="DownloadablePage"
      className="MainContainer m-2 rounded-sm h-[98%] flex justify-between 
  overflow-y-scroll overflow-x-scroll scroll-smooth scrollbar-hide "
    >
      <div className="left w-[40%] sm:w-[35%]  bg-[#e5f1ff] text-[#1d78db] ">
        <div className="mx-5">
          <div className="ProdfilePicture border-4 border-[#1d78db] cursor-pointer bg-transparent my-8 mx-auto w-[6rem] h-[6rem] rounded-full flex items-center justify-center sm:w-[7rem] sm:h-[7rem] xl:w-[10rem] xl:h-[10rem]">
            <div className="file text-center space-y-1">
              <label for="files" className="">
                <i class="fa-solid fa-circle-arrow-up text-2xl" id="file"></i>
              </label>
              <p className="text-sm">Upload Photo</p>
              <input id="files" className="hidden" type="file" />
            </div>
          </div>
          {/* Content 0.1 Start */}

          <div className="content1 space-y-2 my-3 ">
            <div className="contentleft text-[#707070] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-[#0268d7]">
                <i class="fa-solid fa-user-large"></i>{" "}
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <h1 className="text-[#5da9d2] text-[12px] relative font-bold">
                  About
                  <span
                    onClick={() => {
                      a.updateState("about");
                    }}
                    className="mx-5
           px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#0268d7] border border-white text-white "
                  >
                    <i class="fa-solid fa-pen text-xs"></i>
                  </span>
                </h1>
              </div>
            </div>

            <div className="contentleft text-[#707070] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-white">
                {/* <i class="fa-solid fa-phone"></i> */}
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <p className="text-[11px] font-normal text-[#707070]">
                  {a.newState.ProfileInfo}
                </p>
              </div>
            </div>
          </div>

          {/* Content 0.1 End */}

          {/* cONTENT ONE 1 START */}
          <div className="contentleft1 space-y-2 ">
            <div className="contentleft text-[#cbd3e0] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-white">
                {/* <i class="fa-solid fa-phone"></i> */}
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <h1 className="text-[#5da9d2] text-[12px] relative font-bold">
                  Contact us
                  <span
                    onClick={() => {
                      a.updateState("contact");
                    }}
                    className="mx-5
           px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#0268d7] border border-white text-white "
                  >
                    <i class="fa-solid fa-pen text-xs"></i>
                  </span>
                </h1>
              </div>
            </div>

            <div className="contentleft  text-[#707070] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-[blue]">
                <i class="fa-solid fa-location-dot "></i>{" "}
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <p>{a.newState.C_Location1}</p>
                <p>{a.newState.C_Location2}</p>
              </div>
            </div>

            <div className="contentleft  text-[#707070] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-[#0268d7]">
                <i class="fa-solid fa-envelope-open"></i>
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <p>{a.newState.C_Email}</p>
              </div>
            </div>

            <div className="contentleft text-[#707070] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-[#0268d7]">
                <i class="fa-solid fa-phone"></i>
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <p>{a.newState.C_Phone}</p>
              </div>
            </div>

            <div className="contentleft text-[#707070] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-[#0268d7]">
                <i class="fa-solid fa-link"></i>
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <p>{a.newState.C_Link}</p>
              </div>
            </div>
          </div>
          {/* CONTENT ONE 1 START */}

          {/* CONTENT Three 3 START */}
          <div className="contentleft1 space-y-2 my-4 ">
            <div className="contentleft text-[#cbd3e0] flex justify-between items-center">
              <div className="left w-12 text-[14px] text-[#0268d7]">
                <i class="fa-solid fa-book-open"></i>{" "}
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <h1 className="text-[#5da9d2] text-[12px] relative font-bold">
                  Education
                  <span
                    onClick={() => {
                      a.updateState("education");
                    }}
                    className="mx-5
           px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#0268d7] border border-white text-[white]"
                  >
                    <i class="fa-solid fa-pen text-xs"></i>
                  </span>
                </h1>
              </div>
            </div>

            <div className="contentleft my-2 text-[#707070] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-[#0268d7]">
                {/* <i class="fa-solid fa-envelope-open"></i> */}
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                {a.newState.E_DateStart} -{a.newState.E_EndDate}{" "}
                {a.newState.E_Title3} {a.newState.E_Title2}
              </div>
            </div>

            <div className="contentleft  text-[#707070] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-[#0268d7]">
                {/* <i class="fa-solid fa-envelope-open"></i> */}
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                {a.newState.E_DateStart1} -{a.newState.E_EndDate2}{" "}
                {a.newState.E_Title5} {a.newState.E_Title4}
              </div>
            </div>
          </div>
          {/* CONTENT Three 3 START */}
        </div>
      </div>
      <div className="right w-[60%] sm:w-[65%] mx-2 flex flex-col ">
        <div className="contain mx-5 px-2">
          <div
            className="profileName flex flex-col space-y-2
      h-[9rem] my-5  justify-center"
          >
            <h1 className="text-4xl relative tracking-[1px] font-bold text-[#0268d7]">
              {a.newState.ProfileName}
              <span
                onClick={() => {
                  a.updateState("profileName");
                }}
                className="mx-5
           px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white "
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>
            <h2 className="font-normal  text-[16px] text-[#707070]">
              {a.newState.JobRole}
            </h2>
          </div>
          {/* <div className="content1 space-y-2 ">
          <h1 className="text-[#5da9d2] relative text-[12px] font-bold">
            PERSONAL PROFILE
            <span className="mx-5
           px-1 text-sm absolute right-0 top-0 rounded-sm bg-[] border border-white text-white "><i class="fa-solid fa-pen text-xs"></i></span>
          </h1>
          <p className="text-[#7e7e7c] leading-4 text-[11px]">
            A Python developer with 5.8 years of experience in Django, Flask
            for Retail eCommerce, POS and Storage domain.
          </p>
        </div> */}

          <div className="content1 space-y-3 my-3 ">
            <h1 className="text-[#0268d7] mb-4 relative text-[12px] font-bold">
              {a.newState.Title}
              <span
                onClick={() => {
                  a.updateState("expriance");
                }}
                className="mx-5
           px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white "
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>
            <p className="text-[#413f40] text-[16px] font-medium">
              {a.newState.Title2}{" "}
              <span className="text-[#b5b5b5] text-[14px]   font-normal">
                - {a.newState.Title3}
              </span>
            </p>
            <span className="text-[#b5b5b5] text-[14px]   font-normal">
              {a.newState.DateStart} - {a.newState.EndDate}
            </span>
            <p className="text-[#7e7e7c] leading-4 text-[11px]">
              <li className="text-justify my-2 flex">
                <li></li>
                <p>{a.newState.Point1}</p>
              </li>
              <li className="text-justify my-2 flex">
                <li></li>
                <p>{a.newState.Point2}</p>
              </li>
            </p>
          </div>

          <div className="content1 space-y-3 my-3 ">
            <h1 className="text-[#5da9d2] text-[12px] font-bold relative">
              {a.newState.P_Title}
              <span
                onClick={() => {
                  a.updateState("projects");
                }}
                className="mx-5
           px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white  text-white "
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>
            <p className="text-[#434343] text-[15px] pt-2 font-medium leading-4">
              {a.newState.P_Title2}
            </p>
            <p className="text-[#7e7e7c] leading-4 text-[11px]">
              <li className="text-justify my-2 flex">
                <li></li> <p>{a.newState.P_Point1}</p>
              </li>
              <li className="text-justify my-2 flex">
                <li></li>
                <p>{a.newState.P_Point2}</p>
              </li>
            </p>
          </div>

          {/* CONTENT TWO 2 START */}
          <div className="CONTENT2 my-3 ">
            <h1 className="Skills relative my-2 text-[#5da9d2] ">
              Professional Skills
              <span
                onClick={() => {
                  a.updateState("skills");
                }}
                className="mx-5
           px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white "
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>

            <div
              className="skillsProgress my-3 space-y-2 justify-between flex items-center  text-[#333332]
           "
            >
              <div className="item  w-1/2">
                <p className="text-[12px] px-3 font-medium ">
                  {a.newState.Skill1}
                </p>
              </div>
              <div className="item2 w-1/2 flex items-center justify-start space-x-5 ">
                {/* <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7]"></span>
                <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span> */}
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level1 === "1" ||
                a.newState.Level1 === "2" ||
                a.newState.Level1 === "3" ||
                a.newState.Level1 === "4" ||
                a.newState.Level1 === "5" ||
                a.newState.Level1 === "6"
                  ? "bg-[#0268d7]"
                  : "bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level1 === "2" ||
                a.newState.Level1 === "3" ||
                a.newState.Level1 === "4" ||
                a.newState.Level1 === "5" ||
                a.newState.Level1 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level1 === "3" ||
                a.newState.Level1 === "4" ||
                a.newState.Level1 === "5" ||
                a.newState.Level1 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level1 === "4" ||
                a.newState.Level1 === "5" ||
                a.newState.Level1 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level1 === "5" || a.newState.Level1 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${a.newState.Level1 === "6" ? "bg-[#0268d7]" : " bg-[#9ac3ef]"}`}
                ></span>
              </div>
            </div>

            <div
              className="skillsProgress my-3 space-y-2 justify-between flex items-center  text-[#333332]
           "
            >
              <div className="item  w-1/2">
                <p className="text-[12px] px-3 font-medium ">
                  {a.newState.Skill2}
                </p>
              </div>
              <div className="item2 w-1/2 flex items-center justify-start space-x-5 ">
                {/* <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span> */}
           <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level2 === "1" ||
                a.newState.Level2 === "2" ||
                a.newState.Level2 === "3" ||
                a.newState.Level2 === "4" ||
                a.newState.Level2 === "5" ||
                a.newState.Level2 === "6"
                  ? "bg-[#0268d7]"
                  : "bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level2 === "2" ||
                a.newState.Level2 === "3" ||
                a.newState.Level2 === "4" ||
                a.newState.Level2 === "5" ||
                a.newState.Level2 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level2 === "3" ||
                a.newState.Level2 === "4" ||
                a.newState.Level2 === "5" ||
                a.newState.Level2 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level2 === "4" ||
                a.newState.Level2 === "5" ||
                a.newState.Level2 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level2 === "5" || a.newState.Level2 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${a.newState.Level2 === "6" ? "bg-[#0268d7]" : " bg-[#9ac3ef]"}`}
                ></span>
              </div>
            </div>

            <div
              className="skillsProgress my-3 space-y-2 justify-between flex items-center  text-[#333332]
           "
            >
              <div className="item  w-1/2">
                <p className="text-[12px] px-3 font-medium ">
                  {a.newState.Skill3}
                </p>
              </div>
              <div className="item2 w-1/2 flex items-center justify-start space-x-5 ">
                {/* <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7]"></span>
                <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span> */}
               <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level3 === "1" ||
                a.newState.Level3 === "2" ||
                a.newState.Level3 === "3" ||
                a.newState.Level3 === "4" ||
                a.newState.Level3 === "5" ||
                a.newState.Level3 === "6"
                  ? "bg-[#0268d7]"
                  : "bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level3 === "2" ||
                a.newState.Level3 === "3" ||
                a.newState.Level3 === "4" ||
                a.newState.Level3 === "5" ||
                a.newState.Level3 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level3 === "3" ||
                a.newState.Level3 === "4" ||
                a.newState.Level3 === "5" ||
                a.newState.Level3 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level3 === "4" ||
                a.newState.Level3 === "5" ||
                a.newState.Level3 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level3 === "5" || a.newState.Level3 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${a.newState.Level3 === "6" ? "bg-[#0268d7]" : " bg-[#9ac3ef]"}`}
                ></span>
              </div>
            </div>

            <div
              className="skillsProgress my-3 space-y-2 justify-between flex items-center  text-[#333332]
           "
            >
              <div className="item  w-1/2">
                <p className="text-[12px] px-3 font-medium ">
                  {a.newState.Skill4}
                </p>
              </div>
              <div className="item2 w-1/2 flex items-center justify-start space-x-5 ">
                {/* <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7]"></span>
                <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span> */}
              <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level4 === "1" ||
                a.newState.Level4 === "2" ||
                a.newState.Level4 === "3" ||
                a.newState.Level4 === "4" ||
                a.newState.Level4 === "5" ||
                a.newState.Level4 === "6"
                  ? "bg-[#0268d7]"
                  : "bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level4 === "2" ||
                a.newState.Level4 === "3" ||
                a.newState.Level4 === "4" ||
                a.newState.Level4 === "5" ||
                a.newState.Level4 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level4 === "3" ||
                a.newState.Level4 === "4" ||
                a.newState.Level4 === "5" ||
                a.newState.Level4 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level4 === "4" ||
                a.newState.Level4 === "5" ||
                a.newState.Level4 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level4 === "5" || a.newState.Level4 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${a.newState.Level4 === "6" ? "bg-[#0268d7]" : " bg-[#9ac3ef]"}`}
                ></span>
              </div>
            </div>

            <div
              className="skillsProgress my-3 space-y-2 justify-between flex items-center  text-[#333332]
           "
            >
              <div className="item  w-1/2">
                <p className="text-[12px] px-3 font-medium ">
                  {a.newState.Skill5}
                </p>
              </div>
              <div className="item2 w-1/2 flex items-center justify-start space-x-5 ">
                {/* <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7]"></span>
                <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span> */}
               <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level5 === "1" ||
                a.newState.Level5 === "2" ||
                a.newState.Level5 === "3" ||
                a.newState.Level5 === "4" ||
                a.newState.Level5 === "5" ||
                a.newState.Level5 === "6"
                  ? "bg-[#0268d7]"
                  : "bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level5 === "2" ||
                a.newState.Level5 === "3" ||
                a.newState.Level5 === "4" ||
                a.newState.Level5 === "5" ||
                a.newState.Level5 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level5 === "3" ||
                a.newState.Level5 === "4" ||
                a.newState.Level5 === "5" ||
                a.newState.Level5 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level5 === "4" ||
                a.newState.Level5 === "5" ||
                a.newState.Level5 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level5 === "5" || a.newState.Level5 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${a.newState.Level5 === "6" ? "bg-[#0268d7]" : " bg-[#9ac3ef]"}`}
                ></span>
              </div>
            </div>
          </div>
          {/* CONTENT TWO 2 START */}

          {/* Content three 3 Start  */}
          <div className="content1 space-y-5 my-3 ">
            <h1 className="text-[#5da9d2] text-[12px] relative font-bold">
              Interests
              <span
                  onClick={() => {
                    a.updateState("interest");
                  }}
                className="mx-5
           px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white "
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>
            <div className="item2  text-[11px] flex items-center justify-around space-x-5 ">
              <span className="DotLevel  ">{a.newState.C4_Title1}</span>
              <span className="DotLevel  ">{a.newState.C4_Content1}</span>
              <span className="DotLevel  ">{a.newState.C4_Title3}</span>
              <span className="DotLevel  ">{a.newState.C4_Title4}</span>
              <span className="DotLevel  ">{a.newState.C4_Title5}</span>
            </div>
          </div>
          {/* Content three 3 End  */}
        </div>
      </div>
    </div>
  );
};

export default TemplateCom2;
