import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const Profile = () => {
  const a = useContext(stateContext);
  return (
    <>
      <div className="ProfileName m-5 h-full bg-white w-[90%] shadow-xl py-7">
        <div className="center m-5  ">
          <h1 className="text-medium  text-[0.9rem] font-semibold  ">
            Full Name
          </h1>
          <input
            onChange={a.inputValue}
            name="ProfileName"
            value={a.newState.ProfileName}
            className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
            type="text"
            placeholder="Olivia Wilson"
          />
          <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">
            Job Role
          </h1>
          <input
            onChange={a.inputValue}
            name="JobRole"
            value={a.newState.JobRole}
            className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
            type="text"
            placeholder="ML Enginner"
          />
        </div>
        <div className="btn flex justify-center items-center p-2">
          <button onClick={()=>{a.updateState("")}} className="discardbtn text-[#9c50f6] font-semibold ">
            Discard
          </button>
        </div>
      </div>
    </>
  );
};

export default Profile;
