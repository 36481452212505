import React, { useContext, useState } from "react";
import stateContext from "../Context/State/StateContext";

const TemplateCom8 = () => {
  const a = useContext(stateContext);

  const [addSection, setAddSection] = useState([{ section: "" }]);
  const addButton = () => {
    setAddSection([...addSection, { section: "" }]);
  };
  const sectionRemove = (index) => {
    const section = [...addSection];
    section.splice(index, 1);
    setAddSection(section);
  };

  return (
    <>
      <div id="DownloadablePage" className="mainCintainer flex flex-col mx-5 my-2">
        <div className="OverDiv relative flex  h-52 w-full border">
          <div className="Left w-3/5 h-full  "></div>
          <div className="Right w-2/5 h-full z-[-1] bg-[black]"></div>
          <div className="absolute w-full flex justify-center items-center">
            <div className="centerDivProfile w-full flex justify-between items-center ">
              <div className="ProfileImage translate-x-9 w-3/12">
                <div
                  className="ProdfilePicture  bg-[white] border-4 border-[black] cursor-pointer bg-transparent my-8 mx-auto w-[7rem] h-[7rem] rounded-full flex items-center justify-center sm:w-[9rem]
             sm:h-[9rem] xl:w-[10rem] xl:h-[10rem]"
                >
                  <div className="file text-center  space-y-1">
                    <label for="files" className="">
                      <i
                        class="fa-solid fa-circle-arrow-up text-2xl text-[#ff9c01]"
                        id="file"
                      ></i>
                    </label>
                    <p className="text-sm text-[#ff9c01]">Upload Photo</p>
                    <input id="files" className="hidden" type="file" />
                  </div>
                </div>
              </div>
              <div
                className="profileName  bg-[#ff9c01] w-3/4 pl-16 flex flex-col space-y-2
      h-[5.8rem] my-5  justify-center"
              >
                <h1 className="text-4xl  relative uppercase tracking-[1px] font-bold text-white">
                  {a.newState.ProfileName}
                  <span
                    onClick={() => {
                      a.updateState("profileName");
                    }}
                    className="mx-5  px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white "
                  >
                    <i class="fa-solid fa-pen text-xs"></i>
                  </span>
                </h1>
                <h2 className="font-normal  text-[16px] text-white">
                  {a.newState.JobRole}
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/* Bottom Start */}
        <div className="bottom flex">
          <div className="Left w-3/5">
            <div className="CenterLeft w-11/12 mx-auto">
              {/* StartExpriance  */}
              <div className="content1 space-y-3 my-3 ">
                <h1 className="text-[#29292b] w-5/6 text-center p-[5px] bg-[#ff9c01] mb-4 relative text-[16px] font-medium tracking-[1px]">
                  Expriance
                  <span
                    onClick={() => {
                      a.updateState("expriance");
                    }}
                    className="mx-5
           px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white "
                  >
                    <i class="fa-solid fa-pen text-xs"></i>
                  </span>
                </h1>
                <div className="flex flex-col">
                  <span className="text-[#b5b5b5] text-[11px]   font-normal">
                    {a.newState.Title3}
                  </span>
                  <span className="text-[#413f40] text-[11px] font-medium">
                    {a.newState.Title2}
                  </span>
                  <span className="text-[#b5b5b5] text-[10px]   font-normal">
                  {a.newState.DateStart} - {a.newState.EndDate}
                  </span>
                </div>
                <p className="text-[#413f40] font-medium leading-4 text-[11px]">
                  <li className="text-justify my-2 flex">
                    <li></li>
                    <p>
                    {a.newState.Point1}
                    </p>
                  </li>
                  <li className="text-justify my-2 flex">
                    <li></li>
                    <p>
                    {a.newState.Point2}
                    </p>
                  </li>
                </p>
              </div>
              {/* End Expriance  */}
              {/* Project Start  */}
              <div className="content1 space-y-3 my-3 ">
                <h1 className="text-[#29292b] w-5/6 text-center p-[5px] bg-[#ff9c01] font-bold tracking-[1px] text-[15px] relative">
                  Projects
                  <span
                    onClick={() => {
                      a.updateState("projects");
                    }}
                    className="mx-5
           px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white  text-white "
                  >
                    <i class="fa-solid fa-pen text-xs"></i>
                  </span>
                </h1>
                <p className="text-[#434343] text-[14px] pt-2 font-medium leading-4">
                {a.newState.P_Title2}
                </p>
                <p className="text-[#7e7e7c] font-medium leading-4 text-[11px]">
                  <li className="text-justify my-2 flex">
                    <li></li>{" "}
                    <p>
                    {a.newState.P_Point1}
                    </p>
                  </li>
                  <li className="text-justify my-2 flex">
                    <li></li>
                    <p>
                    {a.newState.P_Point2}
                    </p>
                  </li>
                </p>
              </div>

              {/* Project End  */}
              {/* Education Start  */}
              {addSection.map((index) => (
                <div key={index} className="content1 space-y-2 my-3 ">
                  <h1 className="text-[#29292b] w-5/6 text-center p-[5px] bg-[#ff9c01] font-bold tracking-[1px] text-[15px] relative">
                    {a.newState.E_Title}
                    <span
                      onClick={() => {
                        a.updateState("education");
                      }}
                      id="editbtn"
                      className={`mx-5
            px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
              a.RemoveIcons ? "hidden" : ""
            }`}
                    >
                      <i class="fa-solid fa-pen text-xs"></i>
                    </span>
                    {addSection.length > 1 && (
                      <span
                        onClick={() => {
                          sectionRemove(index);
                        }}
                        className={`delete mx-5
            px-1 text-sm absolute  right-5 -top-0 rounded-sm bg-[red] border border-white text-white ${
              a.RemoveIcons ? "hidden" : ""
            }`}
                      >
                        <i class="fa-solid fa-trash"></i>
                      </span>
                    )}
                  </h1>
                  <h1 className="text-[#b5b5b5] text-[11px] font-normal leading-4">
                    {a.newState.E_Title3}
                  </h1>
                  <h1 className="text-[#413f40] text-[12px] font-bold">
                    {a.newState.E_Title2}
                  </h1>
                  <p className="text-[#b5b5b5] text-[11px] font-normal leading-4">
                    {a.newState.DateStart} - {a.newState.EndDate}
                  </p>
                  <p className="text-[#413f40] font-medium leading-4  text-[11px]">
                    <li className="text-justify my-2 flex">
                      <li></li> <p> {a.newState.E_Point1}</p>
                    </li>
                    <li className="text-justify my-2 flex">
                      <li></li>
                      <p>{a.newState.E_Point2}</p>
                    </li>
                    <li className="text-justify my-2 flex">
                      <li></li>
                      <p>{a.newState.E_Point3}</p>
                    </li>
                  </p>
                </div>
              ))}
              <div
                onClick={() => {
                  addButton("1");
                }}
                className={`ADDBUTTON p-2 flex rounded-md font-medium justify-center items-center text-[#8b24f4] border border-[#8b24f4] border-dotted cursor-pointer ${
                  a.RemoveIcons ? "hidden" : ""
                } `}
              >
                <i class="fa-solid fa-plus mx-3"></i> Add Section
              </div>
              {/* Education End  */}
            </div>
          </div>
          <div className="Right w-2/5  bg-[black]">
            <div className="CenterRight mx-4">
              {/* cONTENT ONE 1 START */}
              <div className="contentleft1 space-y-3 my-5 ">
                <h1 className="contactinfo text-[12px] my-4 mx-auto w-[75%] py-1.5 text-center border-2 border-[#ff9c01] text-white relative font-bold">
                  CONTACT INFO
                  <span
                    onClick={() => {
                      a.updateState("contact");
                    }}
                    className={`mx-5
             px-1 text-sm absolute  -right-10 -sm:right-3 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             } `}
                  >
                    <i class="fa-solid fa-pen text-xs"></i>
                  </span>
                </h1>

                <div className="contentleft  text-[white] font-medium flex justify-center ml-10 items-center">
                  <div className="left w-12 text-[10px] text-[#ff9c01]">
                    <i class="fa-solid fa-location-dot "></i>{" "}
                  </div>
                  <div className="right w-4/5 font-normal text-[11px]">
                    <p>{a.newState.C_Location1}</p>
                    <p>{a.newState.C_Location2}</p>
                  </div>
                </div>

                <div className="contentleft  text-[white] font-medium flex justify-center ml-10 items-center">
                  <div className="left w-12 text-[10px] text-[#ff9c01]">
                    <i class="fa-solid fa-envelope-open"></i>
                  </div>
                  <div className="right w-4/5 font-normal text-[11px]">
                    <p>{a.newState.C_Email}</p>
                  </div>
                </div>

                <div className="contentleft text-[white] font-medium flex justify-center ml-10 items-center">
                  <div className="left w-12 text-[10px] text-[#ff9c01]">
                    <i class="fa-solid fa-phone"></i>
                  </div>
                  <div className="right w-4/5 font-normal text-[11px]">
                    <p>{a.newState.C_Phone}</p>
                  </div>
                </div>

                <div className="contentleft text-[white] font-medium flex justify-center ml-10 items-center">
                  <div className="left w-12 text-[10px] text-[#ff9c01]">
                    <i class="fa-solid fa-link"></i>
                  </div>
                  <div className="right w-4/5 font-normal text-[11px]">
                    <p>{a.newState.C_Link}</p>
                  </div>
                </div>
              </div>
              {/* CONTENT ONE 1 START */}

              {/* CONTENT TWO 2 START */}
              <div className="CONTENT2 my-5 space-y-3 ">
                <h1 className="Skills  mx-auto w-[75%] py-1 text-center border-2 border-[#ff9c01] relative my-2 text-white">
                  SKILLS SUMMARY
                  <span
                    onClick={() => {
                      a.updateState("skills");
                    }}
                    className={`mx-5
             px-1 text-sm absolute -right-6 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             }`}
                  >
                    <i class="fa-solid fa-pen text-xs"></i>
                  </span>
                </h1>
                <div
                  className="skillsProgress space-y-2 flex items-center space-x-1 ml-8 text-[#cbd3e0]
             "
                >
                  <span></span>

                  <p className="text-[11px] text-white font-mediumpx-3 w-1/2">
                    {a.newState.Skill1}
                  </p>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level1 === "1" ||
                a.newState.Level1 === "2" ||
                a.newState.Level1 === "3" ||
                a.newState.Level1 === "4"
                  ? "bg-[#ff9c01]"
                  : "bg-[#ffc15d80]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level1 === "2" ||
                a.newState.Level1 === "3" ||
                a.newState.Level1 === "4"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ff9e0180]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level1 === "3" || a.newState.Level1 === "4"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffc15d80]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level1 === "4" ? "bg-[#ff9c01]" : " bg-[#ffc15d80]"
              }`}
                  ></span>
                </div>

                <div
                  className="skillsProgress space-y-2   flex items-center space-x-1 ml-8 text-[#cbd3e0]
             "
                >
                  <span></span>
                  <p className="text-[11px] text-white font-mediumpx-3 w-1/2">
                    {a.newState.Skill2}
                  </p>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level2 === "1" ||
                a.newState.Level2 === "2" ||
                a.newState.Level2 === "3" ||
                a.newState.Level2 === "4"
                  ? "bg-[#ff9c01]"
                  : "bg-[#ffc15d80]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level2 === "2" ||
                a.newState.Level2 === "3" ||
                a.newState.Level2 === "4"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffc15d80]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level2 === "3" || a.newState.Level2 === "4"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffc15d80]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level2 === "4" ? "bg-[#ff9c01]" : " bg-[#ffc15d80]"
              }`}
                  ></span>
                </div>

                <div
                  className="skillsProgress space-y-2   flex items-center space-x-1 ml-8 text-[#cbd3e0]
             "
                >
                  <span></span>
                  <p className="text-[11px] text-white font-mediumpx-3 w-1/2">
                    {a.newState.Skill3}
                  </p>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level3 === "1" ||
                a.newState.Level3 === "2" ||
                a.newState.Level3 === "3" ||
                a.newState.Level3 === "4"
                  ? "bg-[#ff9c01]"
                  : "bg-[#ffc15d80]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level3 === "2" ||
                a.newState.Level3 === "3" ||
                a.newState.Level3 === "4"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffc15d80]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level3 === "3" || a.newState.Level3 === "4"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffc15d80]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level3 === "4" ? "bg-[#ff9c01]" : " bg-[#ffc15d80]"
              }`}
                  ></span>
                </div>

                <div
                  className="skillsProgress space-y-2   flex items-center space-x-1 ml-8 text-[#cbd3e0]
             "
                >
                  <span></span>
                  <p className="text-[11px] text-white font-mediumpx-3 w-1/2">
                    {a.newState.Skill4}
                  </p>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level4 === "1" ||
                a.newState.Level4 === "2" ||
                a.newState.Level4 === "3" ||
                a.newState.Level4 === "4"
                  ? "bg-[#ff9c01]"
                  : "bg-[#ffc15d80]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level4 === "2" ||
                a.newState.Level4 === "3" ||
                a.newState.Level4 === "4"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffc15d80]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level4 === "3" || a.newState.Level4 === "4"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffc15d80]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level4 === "4" ? "bg-[#ff9c01]" : " bg-[#ffc15d80]"
              }`}
                  ></span>
                </div>

                <div
                  className="skillsProgress space-y-2   flex items-center space-x-1 ml-8 text-[#cbd3e0]
             "
                >
                  <span></span>
                  <p className="text-[11px] text-white font-mediumpx-3 w-1/2">
                    {a.newState.Skill5}
                  </p>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level5 === "1" ||
                a.newState.Level5 === "2" ||
                a.newState.Level5 === "3" ||
                a.newState.Level5 === "4"
                  ? "bg-[#ff9c01]"
                  : "bg-[#ffc15d80]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level5 === "2" ||
                a.newState.Level5 === "3" ||
                a.newState.Level5 === "4"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffc15d80]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level5 === "3" || a.newState.Level5 === "4"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffc15d80]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level5 === "4" ? "bg-[#ff9c01]" : " bg-[#ffc15d80]"
              }`}
                  ></span>
                </div>

                <div
                  className="skillsProgress space-y-2   flex items-center space-x-1 ml-8 text-[#cbd3e0]
             "
                >
                  <span></span>
                  <p className="text-[11px] text-white font-mediumpx-3 w-1/2">
                    {a.newState.Skill6}
                  </p>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level6 === "1" ||
                a.newState.Level6 === "2" ||
                a.newState.Level6 === "3" ||
                a.newState.Level6 === "4"
                  ? "bg-[#ff9c01]"
                  : "bg-[#ffc15d80]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level6 === "2" ||
                a.newState.Level6 === "3" ||
                a.newState.Level6 === "4"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffc15d80]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level6 === "3" || a.newState.Level6 === "4"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffc15d80]"
              }`}
                  ></span>
                  <span
                    className={`DotLevel p-[3px] rounded-full 
              ${
                a.newState.Level6 === "4" ? "bg-[#ff9c01]" : " bg-[#ffc15d80]"
              }`}
                  ></span>
                </div>
              </div>
              {/* CONTENT TWO 2 START */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateCom8;
