import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const TemplateCom3 = () => {
  const a = useContext(stateContext);

  return (
    <>
      <div id="DownloadablePage" className="MainContainer m-1 ">
        <div className="top h-[20%] mx-auto w-[98%] bg-[#26252b] flex  items-center justify-around">
          <div
            className="profileName flex flex-col space-y-
        h-[9rem] justify-center mx-5 "
          >
            <h1 className="text-[32px] relative tracking-[1px] font-bold text-[#ffcc01]">
              {a.newState.ProfileName}
              <span
                onClick={() => {
                  a.updateState("profileName");
                }}
                className="mx-5
             px-1 text-sm absolute -right-14 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white"
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>
            <h2 className="font-normal text-[14px] text-[white]">
              {a.newState.JobRole}
            </h2>
          </div>
          <div className="ProdfilePicture cursor-pointer bg-[white] my-8 mx-auto w-[6rem] h-[6rem] rounded-full flex items-center justify-center sm:w-[8.5rem] sm:h-[8.5rem] xl:w-[8rem] xl:h-[8rem] text-[#ffcc01] border-4 border-[#ffcc01] ">
            <div className="file text-center space-y-1">
              <label for="files" className="">
                <i class="fa-solid fa-circle-arrow-up text-2xl" id="file"></i>
              </label>
              <p className="text-sm">Upload Photo</p>
              <input id="files" className="hidden" type="file" />
            </div>
          </div>
        </div>
        {/* BottomContainer Start  */}

        <div
          className="Bottom mx-2 rounded-sm  h-auto min-h-[80%] flex flex-row-reverse justify-between 
    overflow-y-scroll overflow-x-scroll scroll-smooth scrollbar-hide "
        >
          <div
            className="left w-[45%] sm:w-[40%]  bg-[#ebebeb] text-white {
          
      } "
          >
            <div className="mx-5">
              {/* cONTENT ONE 1 START */}
              <div className="contentleft1 space-y-2 my-4 ">
                <div className="contentleft text-[#cbd3e0] flex justify-between items-center">
                  <div className="right w-4/5 font-normal text-[11px]">
                    <h1 className="text-[black] text-[16px] relative font-bold">
                      Education
                      <span
                       onClick={() => {
                        a.updateState("education");
                      }}
                        className="mx-5
           px-1 text-sm absolute -right-10 top-0 rounded-sm bg-[#5f0aa8] border border-white text-[white]"
                      >
                        <i class="fa-solid fa-pen text-xs"></i>
                      </span>
                    </h1>
                    <span className="w-8 my-1 flex h-[1px] rounded-full  bg-black"></span>
                  </div>
                </div>

                <div className="contentleft  text-[#707070] leading-5">
                  <div className="right w-4/5 text-[#757575] font-normal text-[11px]">
                  {a.newState.E_DateStart} -{a.newState.E_EndDate}{" "}
                {a.newState.E_Title3} {a.newState.E_Title2}
                  </div>
                </div>

                <div className="contentleft  text-[#707070] leading-5">
                  <div className="right w-4/5 text-[#757575] font-normal text-[11px]">
                  {a.newState.E_DateStart1} -{a.newState.E_EndDate2}{" "}
                {a.newState.E_Title5} {a.newState.E_Title4}
                  </div>
                </div>
              </div>
              {/* CONTENT ONE 1 START */}

              {/* CONTENT TWO 2 START */}
              <div className="contentleft1 space-y-1 ">
                <h1 className="contactinfo text-black text-[14px] relative font-bold">
                  Contact Info
                  <span
                   onClick={() => {
                    a.updateState("contact");
                  }}
                    className="mx-5
             px-1 text-sm absolute  -right-10  top-0 rounded-sm bg-[#5f0aa8] border border-white text-white "
                  >
                    <i class="fa-solid fa-pen text-xs"></i>
                  </span>
                </h1>
                <span className="w-10 flex  h-[1px] rounded-full  bg-black"></span>

                <div className="contentleft text-[#757575] flex flex-col justify-start items-start">
                  <div className="left w-12 text-[10px] text-black">
                    <span className="text-[12px] font-bold text-[#757575]">
                      Address
                    </span>
                  </div>
                  <div className="right font-normal text-[#757575] text-[11px]">
                  <p>{a.newState.C_Location1}</p>
                <p>{a.newState.C_Location2}</p>
                  </div>
                </div>

                <div className="contentleft  text-[#757575] flex flex-col justify-start items-start">
                  <div className="left w-12 text-[10px] text-black">
                    <span className="text-[12px] font-bold text-[#757575]">
                      Email
                    </span>
                  </div>
                  <div className="right font-normal text-[11px]">
                  <p>{a.newState.C_Email}</p>
                  </div>
                </div>

                <div className="contentleft  text-[#757575] flex flex-col justify-start items-start">
                  <div className="left w-12 text-[10px] text-black">
                    <span className="text-[12px] font-bold text-[#757575]">
                      Phone
                    </span>
                  </div>
                  <div className="right font-normal text-[11px]">
                  <p>{a.newState.C_Phone}</p>
                  </div>
                </div>

                <div className="contentleft  text-[#757575] flex flex-col justify-start items-start">
                  <div className="left w-12 text-[10px] text-black">
                    <span className="text-[12px] font-bold text-[#757575]">
                      Portfolio
                    </span>
                  </div>
                  <div className="right font-normal text-[11px]">
                  <p>{a.newState.C_Link}</p>
                  </div>
                </div>
              </div>
              {/* CONTENT TWO 2 START */}

              {/* Content three 3 Start  */}
              <div className="content1 space-y-2 my-3 text-[#757575] ">
                <h1 className="text-[black] text-[14px] relative font-bold">
                  Language
                  <span
                   onClick={() => {
                    a.updateState("interest");
                  }}
                    className="mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white "
                  >
                    <i class="fa-solid fa-pen text-xs"></i>
                  </span>
                </h1>
                <span className="w-8  flex h-[1px] rounded-full  bg-black"></span>
                <div className="item2  text-[11px] flex flex-col items-start justify-around space-y-3 ">
                <span className="DotLevel  ">{a.newState.C4_Title1}</span>
              <span className="DotLevel  ">{a.newState.C4_Content1}</span>
              <span className="DotLevel  ">{a.newState.C4_Title3}</span>
              <span className="DotLevel  ">{a.newState.C4_Title4}</span>
                </div>
              </div>
              {/* Content three 3 End  */}
            </div>
          </div>
          {/* Right Container Start  */}
          <div className="right  mx-2 flex flex-col ">
            <div className="contain  my-3 px-2">
              <div className="right w-4/5 font-normal text-[11px]">
                <h1 className="text-[black] text-[14px] relative font-bold">
                  About
                  <span
                    onClick={() => {
                      a.updateState("about");
                    }}
                    className="mx-5
           px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white "
                  >
                    <i class="fa-solid fa-pen text-xs"></i>
                  </span>
                </h1>
                <span className="w-8 my-1 flex h-[1px] rounded-full  bg-black"></span>
                <div className="right w-4/5 my-4 font-normal text-[11px]">
                  <p className="text-[11px] font-medium text-[#707070]">
                  {a.newState.ProfileInfo}
                  </p>
                </div>
              </div>

              <div className="content1 space-y-1 my-3 break-words w-[90%] ">
                <h1 className="text-[black] relative text-[14px] font-bold">
                {a.newState.Title}
                  <span
                   onClick={() => {
                    a.updateState("expriance");
                  }} 
                    className="mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white "
                  >
                    <i class="fa-solid fa-pen text-xs"></i>
                  </span>
                </h1>
                <span className="w-8 my-1 flex h-[1px] rounded-full  bg-black"></span>

                <div className="jobRoleContain">
                  <h1 className="text-[#757575] text-[12px] font-medium">
                  {a.newState.Title2}
                  </h1>
                  <div className="JobRole flex justify-start items-center">
                    <p className="text-[#b5b5b5] text-[12px] font-normal leading-4">
                    {a.newState.Title3}
                    </p>
                    <p className="text-[12px] text-[#b5b5b5] ">{a.newState.DateStart} - {a.newState.EndDate}</p>
                  </div>
                  <p className="text-[#7e7e7c] leading-4 text-[11px]">
                    <li className="text-justify my-2 flex">
                      <li className="text-[#ffcc01]"></li>{" "}
                      <p>
                      {a.newState.Point1}
                      </p>
                    </li>
                    <li className="text-justify my-2 flex">
                      <li className="text-[#ffcc01]"></li>
                      <p>
                      {a.newState.Point2}
                      </p>
                    </li>
                  </p>
                </div>
                <div className="jobRoleContain">
                  <h1 className="text-[#757575] text-[12px] font-medium">
                  {a.newState.Sec_Title2}
                  </h1>
                  <div className="JobRole flex justify-start items-center">
                    <p className="text-[#b5b5b5] text-[12px] font-normal leading-4">
                    {a.newState.Sec_Title3}
                    </p>
                    <p className="text-[12px] text-[#b5b5b5] ">{a.newState.Sec_DateStart} - {a.newState.Sec_EndDate}</p>
                  </div>
                  <p className="text-[#7e7e7c] leading-4 text-[11px]">
                    <li className="text-justify my-2 flex">
                      <li className="text-[#ffcc01]"></li>{" "}
                      <p>
                      {a.newState.Sec_Point1}
                      </p>
                    </li>
                    <li className="text-justify my-2 flex">
                      <li className="text-[#ffcc01]"></li>
                      <p>
                      {a.newState.Sec_Point2}
                      </p>
                    </li>
                  </p>
                </div>
              </div>

              <div className="ADDBUTTON p-2 flex rounded-sm justify-center items-center text-[#8b24f4] border border-[#8b24f4] border-dotted">
                <i class="fa-solid fa-plus mx-3"></i> Add Section
              </div>

              <div className="CONTENT2 my-3 ">
                <h1 className="Skills relative my-2 text-[#5da9d2] ">
                  Skills
                  <span
                    onClick={() => {
                      a.updateState("skills");
                    }}
                    className="mx-5
           px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white "
                  >
                    <i class="fa-solid fa-pen text-xs"></i>
                  </span>
                </h1>

                <div
                  className="skillsProgress my-3 space-y-2 justify-between flex items-center  text-[#333332]
           "
                >
                  <div className="item  w-1/2">
                    <p className="text-[12px]  font-medium "> {a.newState.Skill1}</p>
                  </div>
                  <div className="item2 w-1/2 flex space-x-3 justify-start  ">
                    {/* <span className="DotLevel p-[0.25rem] rounded-full bg-[#ff9c01] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full  bg-[#ff9c01] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full  bg-[#ff9c01]"></span>
                    <span className="DotLevel p-[0.25rem] rounded-full bg-[#ffd799] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full bg-[#ffd799] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full bg-[#ffd799] "></span> */}
                    <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level1 === "1" ||
                a.newState.Level1 === "2" ||
                a.newState.Level1 === "3" ||
                a.newState.Level1 === "4" ||
                a.newState.Level1 === "5" ||
                a.newState.Level1 === "6"
                  ? "bg-[#ff9c01]"
                  : "bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level1 === "2" ||
                a.newState.Level1 === "3" ||
                a.newState.Level1 === "4" ||
                a.newState.Level1 === "5" ||
                a.newState.Level1 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level1 === "3" ||
                a.newState.Level1 === "4" ||
                a.newState.Level1 === "5" ||
                a.newState.Level1 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level1 === "4" ||
                a.newState.Level1 === "5" ||
                a.newState.Level1 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level1 === "5" || a.newState.Level1 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${a.newState.Level1 === "6" ? "bg-[#ff9c01]" : " bg-[#ffd799] "}`}
                ></span>
                  </div>
                </div>

                <div
                  className="skillsProgress my-3 space-y-2 justify-between flex items-center  text-[#333332]
           "
                >
                  <div className="item  w-1/2">
                    <p className="text-[12px]  font-medium "> {a.newState.Skill2}</p>
                  </div>
                  <div className="item2 w-1/2 flex items-center justify-start space-x-3 ">
                    {/* <span className="DotLevel p-[0.25rem] rounded-full bg-[#ff9c01] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full  bg-[#ff9c01] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full bg-[#ffd799] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full bg-[#ffd799] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full bg-[#ffd799] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full bg-[#ffd799] "></span> */}
                    <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level2 === "1" ||
                a.newState.Level2 === "2" ||
                a.newState.Level2 === "3" ||
                a.newState.Level2 === "4" ||
                a.newState.Level2 === "5" ||
                a.newState.Level2 === "6"
                  ? "bg-[#ff9c01]"
                  : "bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level2 === "2" ||
                a.newState.Level2 === "3" ||
                a.newState.Level2 === "4" ||
                a.newState.Level2 === "5" ||
                a.newState.Level2 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level2 === "3" ||
                a.newState.Level2 === "4" ||
                a.newState.Level2 === "5" ||
                a.newState.Level2 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level2 === "4" ||
                a.newState.Level2 === "5" ||
                a.newState.Level2 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level2 === "5" || a.newState.Level2 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${a.newState.Level2 === "6" ? "bg-[#ff9c01]" : " bg-[#ffd799] "}`}
                ></span>
                  </div>
                </div>

                <div
                  className="skillsProgress my-3 space-y-2 justify-between flex items-center  text-[#333332]
           "
                >
                  <div className="item  w-1/2">
                    <p className="text-[12px]  font-medium "> {a.newState.Skill3}</p>
                  </div>
                  <div className="item2 w-1/2 flex items-center justify-start space-x-3 ">
                    {/* <span className="DotLevel p-[0.25rem] rounded-full bg-[#ff9c01] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full  bg-[#ff9c01] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full  bg-[#ff9c01]"></span>
                    <span className="DotLevel p-[0.25rem] rounded-full bg-[#ff9c01] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full bg-[#ffd799] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full bg-[#ffd799] "></span> */}
                    <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level3 === "1" ||
                a.newState.Level3 === "2" ||
                a.newState.Level3 === "3" ||
                a.newState.Level3 === "4" ||
                a.newState.Level3 === "5" ||
                a.newState.Level3 === "6"
                  ? "bg-[#ff9c01]"
                  : "bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level3 === "2" ||
                a.newState.Level3 === "3" ||
                a.newState.Level3 === "4" ||
                a.newState.Level3 === "5" ||
                a.newState.Level3 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level3 === "3" ||
                a.newState.Level3 === "4" ||
                a.newState.Level3 === "5" ||
                a.newState.Level3 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level3 === "4" ||
                a.newState.Level3 === "5" ||
                a.newState.Level3 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level3 === "5" || a.newState.Level3 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${a.newState.Level3 === "6" ? "bg-[#ff9c01]" : " bg-[#ffd799] "}`}
                ></span>
                  </div>
                </div>

                <div
                  className="skillsProgress my-3 space-y-2 justify-between flex items-center  text-[#333332]
           "
                >
                  <div className="item  w-1/2">
                    <p className="text-[12px]  font-medium "> {a.newState.Skill4}</p>
                  </div>
                  <div className="item2 w-1/2 flex items-center justify-start space-x-3 ">
                    {/* <span className="DotLevel p-[0.25rem] rounded-full bg-[#ff9c01] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full  bg-[#ff9c01] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full  bg-[#ff9c01]"></span>
                    <span className="DotLevel p-[0.25rem] rounded-full bg-[#ff9c01] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full bg-[#ffd799] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full bg-[#ffd799] "></span> */}
                    <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level4 === "1" ||
                a.newState.Level4 === "2" ||
                a.newState.Level4 === "3" ||
                a.newState.Level4 === "4" ||
                a.newState.Level4 === "5" ||
                a.newState.Level4 === "6"
                  ? "bg-[#ff9c01]"
                  : "bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level4 === "2" ||
                a.newState.Level4 === "3" ||
                a.newState.Level4 === "4" ||
                a.newState.Level4 === "5" ||
                a.newState.Level4 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level4 === "3" ||
                a.newState.Level4 === "4" ||
                a.newState.Level4 === "5" ||
                a.newState.Level4 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level4 === "4" ||
                a.newState.Level4 === "5" ||
                a.newState.Level4 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level4 === "5" || a.newState.Level4 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${a.newState.Level4 === "6" ? "bg-[#ff9c01]" : " bg-[#ffd799] "}`}
                ></span>
                  </div>
                </div>

                <div
                  className="skillsProgress my-3 space-y-2 justify-between flex items-center  text-[#333332]
           "
                >
                  <div className="item  w-1/2">
                    <p className="text-[12px]  font-medium "> {a.newState.Skill5}</p>
                  </div>
                  <div className="item2 w-1/2 flex items-center justify-start space-x-3 ">
                    {/* <span className="DotLevel p-[0.25rem] rounded-full bg-[#ff9c01] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full  bg-[#ff9c01] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full  bg-[#ff9c01]"></span>
                    <span className="DotLevel p-[0.25rem] rounded-full bg-[#ff9c01] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full bg-[#ff9c01] "></span>
                    <span className="DotLevel p-[0.25rem] rounded-full bg-[#ff9c01] "></span> */}
                    <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level5 === "1" ||
                a.newState.Level5 === "2" ||
                a.newState.Level5 === "3" ||
                a.newState.Level5 === "4" ||
                a.newState.Level5 === "5" ||
                a.newState.Level5 === "6"
                  ? "bg-[#ff9c01]"
                  : "bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level5 === "2" ||
                a.newState.Level5 === "3" ||
                a.newState.Level5 === "4" ||
                a.newState.Level5 === "5" ||
                a.newState.Level5 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level5 === "3" ||
                a.newState.Level5 === "4" ||
                a.newState.Level5 === "5" ||
                a.newState.Level5 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level5 === "4" ||
                a.newState.Level5 === "5" ||
                a.newState.Level5 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level5 === "5" || a.newState.Level5 === "6"
                  ? "bg-[#ff9c01]"
                  : " bg-[#ffd799] "
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${a.newState.Level5 === "6" ? "bg-[#ff9c01]" : " bg-[#ffd799] "}`}
                ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Right Container End  */}
        </div>

        {/* Bottom Cointainer End  */}
      </div>
    </>
  );
};

export default TemplateCom3;
