import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const Skills = () => {
  const a = useContext(stateContext);
  return (
    <div className="ProfileName m-5 h-full bg-white w-[90%] shadow-xl py-7">
      <div className="center m-5  ">
        <h1 className="text-medium  text-[0.9rem] font-semibold  ">Skill 1</h1>
        <input
          onChange={a.inputValue}
          name="Skill1"
          value={a.newState.Skill1}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Olivia Wilson"
        />
        <div className="Level mb-5 flex w-1/2 justify-cente space-x-2">
          <span></span>
          <span onClick={()=>{a.updateLevel("Level1","1")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level1 === "1" ? "bg-[#9c50f6] text-white" : "" } `}>
            1
          </span>
          <span onClick={()=>{a.updateLevel("Level1","2")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level1 === "2" ? "bg-[#9c50f6] text-white" : "" } `}>
            2
          </span>
          <span onClick={()=>{a.updateLevel("Level1","3")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level1 === "3" ? "bg-[#9c50f6] text-white" : "" } `}>
            3
          </span>
          <span onClick={()=>{a.updateLevel("Level1","4")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level1 === "4" ? "bg-[#9c50f6] text-white" : "" } `}>
            4
          </span>
          {/* // Extra Level  Start  */}
          <span onClick={()=>{a.updateLevel("Level1","5")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level1 === "5" ? "bg-[#9c50f6] text-white" : "" } `}>
            5
          </span>
          <span onClick={()=>{a.updateLevel("Level1","6")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level1 === "6" ? "bg-[#9c50f6] text-white" : "" } `}>
            6
          </span>
          {/* Extra Level End  */}
        </div>
      
        <h1 className="text-medium  text-[0.9rem] font-semibold  ">Skill 2</h1>
        <input
          onChange={a.inputValue}
          name="Skill2"
          value={a.newState.Skill2}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Olivia Wilson"
        />
        <div className="Level mb-5 flex w-1/2 justify-cente space-x-2">
          <span></span>
          <span onClick={()=>{a.updateLevel("Level2","1")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level2 === "1" ? "bg-[#9c50f6] text-white" : "" } `}>
            1
          </span>
          <span onClick={()=>{a.updateLevel("Level2","2")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level2 === "2" ? "bg-[#9c50f6] text-white" : "" } `}>
            2
          </span>
          <span onClick={()=>{a.updateLevel("Level2","3")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level2 === "3" ? "bg-[#9c50f6] text-white" : "" } `}>
            3
          </span>
          <span onClick={()=>{a.updateLevel("Level2","4")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level2 === "4" ? "bg-[#9c50f6] text-white" : "" } `}>
            4
          </span>
           {/* // Extra Level  Start  */}
           <span onClick={()=>{a.updateLevel("Level2","5")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level2 === "5" ? "bg-[#9c50f6] text-white" : "" } `}>
            5
          </span>
          <span onClick={()=>{a.updateLevel("Level2","6")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level2 === "6" ? "bg-[#9c50f6] text-white" : "" } `}>
            6
          </span>
          {/* Extra Level End  */}
        </div>

        <h1 className="text-medium  text-[0.9rem] font-semibold  ">Skill 3</h1>
        <input
          onChange={a.inputValue}
          name="Skill3"
          value={a.newState.Skill3}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Olivia Wilson"
        />
        <div className="Level mb-5 flex w-1/2 justify-cente space-x-2">
          <span></span>
          <span onClick={()=>{a.updateLevel("Level3","1")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level3 === "1" ? "bg-[#9c50f6] text-white" : "" } `}>
            1
          </span>
          <span onClick={()=>{a.updateLevel("Level3","2")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level3 === "2" ? "bg-[#9c50f6] text-white" : "" } `}>
            2
          </span>
          <span onClick={()=>{a.updateLevel("Level3","3")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level3 === "3" ? "bg-[#9c50f6] text-white" : "" } `}>
            3
          </span>
          <span onClick={()=>{a.updateLevel("Level3","4")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level3 === "4" ? "bg-[#9c50f6] text-white" : "" } `}>
            4
          </span>
           {/* // Extra Level  Start  */}
           <span onClick={()=>{a.updateLevel("Level3","5")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level3 === "5" ? "bg-[#9c50f6] text-white" : "" } `}>
            5
          </span>
          <span onClick={()=>{a.updateLevel("Level3","6")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level3 === "6" ? "bg-[#9c50f6] text-white" : "" } `}>
            6
          </span>
          {/* Extra Level End  */}
        </div>

        <h1 className="text-medium  text-[0.9rem] font-semibold  ">Skill 4</h1>
        <input
          onChange={a.inputValue}
          name="Skill4"
          value={a.newState.Skill4}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Olivia Wilson"
        />
        <div className="Level mb-5 flex w-1/2 justify-cente space-x-2">
          <span></span>
          <span onClick={()=>{a.updateLevel("Level4","1")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level4 === "1" ? "bg-[#9c50f6] text-white" : "" } `}>
            1
          </span>
          <span onClick={()=>{a.updateLevel("Level4","2")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level4 === "2" ? "bg-[#9c50f6] text-white" : "" } `}>
            2
          </span>
          <span onClick={()=>{a.updateLevel("Level4","3")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level4 === "3" ? "bg-[#9c50f6] text-white" : "" } `}>
            3
          </span>
          <span onClick={()=>{a.updateLevel("Level4","4")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level4 === "4" ? "bg-[#9c50f6] text-white" : "" } `}>
            4
          </span>
           {/* // Extra Level  Start  */}
           <span onClick={()=>{a.updateLevel("Level4","5")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level4 === "5" ? "bg-[#9c50f6] text-white" : "" } `}>
            5
          </span>
          <span onClick={()=>{a.updateLevel("Level4","6")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level4 === "6" ? "bg-[#9c50f6] text-white" : "" } `}>
            6
          </span>
          {/* Extra Level End  */}
        </div>

        <h1 className="text-medium  text-[0.9rem] font-semibold  ">Skill 5</h1>
        <input
          onChange={a.inputValue}
          name="Skill5"
          value={a.newState.Skill5}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Olivia Wilson"
        />
        <div className="Level mb-5 flex w-1/2 justify-cente space-x-2">
          <span></span>
          <span onClick={()=>{a.updateLevel("Level5","1")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level5 === "1" ? "bg-[#9c50f6] text-white" : "" } `}>
            1
          </span>
          <span onClick={()=>{a.updateLevel("Level5","2")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level5 === "2" ? "bg-[#9c50f6] text-white" : "" } `}>
            2
          </span>
          <span onClick={()=>{a.updateLevel("Level5","3")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level5 === "3" ? "bg-[#9c50f6] text-white" : "" } `}>
            3
          </span>
          <span onClick={()=>{a.updateLevel("Level5","4")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level5 === "4" ? "bg-[#9c50f6] text-white" : "" } `}>
            4
          </span>
           {/* // Extra Level  Start  */}
           <span onClick={()=>{a.updateLevel("Level5","5")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level5 === "5" ? "bg-[#9c50f6] text-white" : "" } `}>
            5
          </span>
          <span onClick={()=>{a.updateLevel("Level5","6")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level5 === "6" ? "bg-[#9c50f6] text-white" : "" } `}>
            6
          </span>
          {/* Extra Level End  */}
        </div>

        <h1 className="text-medium  text-[0.9rem] font-semibold  ">Skill 6</h1>
        <input
          onChange={a.inputValue}
          name="Skill6"
          value={a.newState.Skill6}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Olivia Wilson"
        />
        <div className="Level mb-5 flex w-1/2 justify-cente space-x-2">
          <span></span>
          <span onClick={()=>{a.updateLevel("Level6","1")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level6 === "1" ? "bg-[#9c50f6] text-white" : "" } `}>
            1
          </span>
          <span onClick={()=>{a.updateLevel("Level6","2")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level6 === "2" ? "bg-[#9c50f6] text-white" : "" } `}>
            2
          </span>
          <span onClick={()=>{a.updateLevel("Level6","3")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level6 === "3" ? "bg-[#9c50f6] text-white" : "" } `}>
            3
          </span>
          <span onClick={()=>{a.updateLevel("Level6","4")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level6 === "4" ? "bg-[#9c50f6] text-white" : "" } `}>
            4
          </span>
           {/* // Extra Level  Start  */}
           <span onClick={()=>{a.updateLevel("Level6","5")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level6 === "5" ? "bg-[#9c50f6] text-white" : "" } `}>
            5
          </span>
          <span onClick={()=>{a.updateLevel("Level6","6")}} className={`h-[20px] w-[20px] rounded-full cursor-pointer flex justify-center items-center text-[10px] 
         ${ a.newState.Level6 === "6" ? "bg-[#9c50f6] text-white" : "" } `}>
            6
          </span>
          {/* Extra Level End  */}
        </div>
      
      </div>
      <div className="btn flex justify-center items-center p-2">
        <button
          onClick={() => {
            a.updateState("");
          }}
          className="discardbtn text-[#9c50f6] font-semibold "
        >
          Discard
        </button>
      </div>
    </div>
  );
};

export default Skills;
