import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const TempCom4Work = () => {
  const a = useContext(stateContext);

  return (
    <div className="ProfileName m-5 h-full bg-white w-[90%] shadow-xl py-7">
      <div className="center m-5  ">
        <h1 className="text-medium  text-[0.9rem] font-semibold  ">Works</h1>
        <input
          onChange={a.inputValue}
          name="C4W_Title"
          value={a.newState.C4W_Title}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          //
        />

        <input
          onChange={a.inputValue}
          name="C4W_Content1"
          value={a.newState.C4W_Content1}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
        />
        <input
          onChange={a.inputValue}
          name="C4W_Point1"
          value={a.newState.C4W_Point1}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
        />
        <hr className="my-4 border-black border-dotted" />

        <input
          onChange={a.inputValue}
          name="C4W_Title2"
          value={a.newState.C4W_Title2}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />
        <input
          onChange={a.inputValue}
          name="C4W_Content2"
          value={a.newState.C4W_Content2}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />
        <input
          onChange={a.inputValue}
          name="C4W_Point2"
          value={a.newState.C4W_Point2}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
        />
        <input
          onChange={a.inputValue}
          name="C4W_Point3"
          value={a.newState.C4W_Point3}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
        />
        <hr className="my-4 border-black border-dotted" />

        <input
          onChange={a.inputValue}
          name="C4W_Title3"
          value={a.newState.C4W_Title3}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
        />
        <input
          onChange={a.inputValue}
          name="C4W_Content3"
          value={a.newState.C4W_Content3}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
        />
        <input
          onChange={a.inputValue}
          name="C4W_Point4"
          value={a.newState.C4W_Point4}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
        />
        <input
          onChange={a.inputValue}
          name="C4W_Point5"
          value={a.newState.C4W_Point5}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
        />
        <hr className="my-4 border-black border-dotted" />
      </div>
      <div className="btn flex justify-center items-center p-2">
        <button
          onClick={() => {
            a.updateState("");
          }}
          className="discardbtn text-[#9c50f6] font-semibold "
        >
          Discard
        </button>
      </div>
    </div>
  );
};

export default TempCom4Work;
