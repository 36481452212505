import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const ProjectCom6 = () => {
  const a = useContext(stateContext);

  return (
    <div className="ProfileName m-5 h-full bg-white w-[90%] max-h-[46.5rem] overflow-y-scroll  shadow-xl py-7">
      <div className="center m-5  ">
        {/* Content 1 Start  */}

        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">
          Project Title  / Univercity / College 
        </h1>
        <input
          onChange={a.inputValue}
          name="Tem6_Title1"
          value={a.newState.Tem6_Title1}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Trading Platform"
        />

        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">
          Technology / Course / Stream
        </h1>
        <input
          onChange={a.inputValue}
          name="Tem6_Title01"
          value={a.newState.Tem6_Title01}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Node.js, Express, Firebase, React, WebSocket"
        />

        <h1 className="text-medium mt-2 text-[0.9rem] font-semibold  ">
          Des
        </h1>
        <input
          onChange={a.inputValue}
          name="Tem6_Des1"
          value={a.newState.Tem6_Des1}
          className="EditProfile my-4 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="A personalized trading platform using other platform's API. This platform allows the user to place multiple orders for multiple clients with a single click."
        />

        <h1 className="text-medium my-1 text-[0.9rem] font-semibold ">
          Start Date
        </h1>
        <input
          onChange={a.inputValue}
          name="Tem6_DateStart1"
          value={a.newState.Tem6_DateStart1}
          className="EditProfile my-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />

        <h1 className="text-medium my-1 text-[0.9rem] font-semibold ">
          End Date
        </h1>
        <input
          onChange={a.inputValue}
          name="Tem6_DateEnd1"
          value={a.newState.Tem6_DateEnd1}
          className="EditProfile mt-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />
        <span className="text-xs mb-2 text-gray-400 font-bold">
          Leave it blank to mark it as Present
        </span>
        {/* Content 1 End  */}

        {/* Second Content Start  */}
        <h1 className="text-medium my-2 mt-12  text-[0.9rem] font-semibold ">
          Project Title / Univercity / College 
        </h1>
        <input
          onChange={a.inputValue}
          name="Tem6_Title2"
          value={a.newState.Tem6_Title2}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Trading Platform"
        />

        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">
          Technology / Course / Stream
        </h1>
        <input
          onChange={a.inputValue}
          name="Tem6_Title02"
          value={a.newState.Tem6_Title02}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Node.js, Express, Firebase, React, WebSocket"
        />

        <h1 className="text-medium mt-2 text-[0.9rem] font-semibold  ">
          Des
        </h1>
        <input
          onChange={a.inputValue}
          name="Tem6_Des2"
          value={a.newState.Tem6_Des2}
          className="EditProfile my-4 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="A personalized trading platform using other platform's API. This platform allows the user to place multiple orders for multiple clients with a single click."
        />

        <h1 className="text-medium my-1 text-[0.9rem] font-semibold ">
          Start Date
        </h1>
        <input
          onChange={a.inputValue}
          name="Tem6_DateStart1"
          value={a.newState.Tem6_DateStart1}
          className="EditProfile my-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />

        <h1 className="text-medium my-1 text-[0.9rem] font-semibold ">
          End Date
        </h1>
        <input
          onChange={a.inputValue}
          name="Tem6_DateEnd2"
          value={a.newState.Tem6_DateEnd2}
          className="EditProfile mt-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />
        <span className="text-xs mb-2 text-gray-400 font-bold">
          Leave it blank to mark it as Present
        </span>
        {/* Second Content End */}

        
        {/* Third COntent Start  */}
        <h1 className="text-medium my-2 mt-12 text-[0.9rem] font-semibold ">
          Project Title / Univercity / College
        </h1>
        <input
          onChange={a.inputValue}
          name="Tem6_Title3"
          value={a.newState.Tem6_Title3}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Trading Platform"
        />

        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">
          Technology / Course / Stream
        </h1>
        <input
          onChange={a.inputValue}
          name="Tem6_Title02"
          value={a.newState.Tem6_Title02}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Node.js, Express, Firebase, React, WebSocket"
        />

        <h1 className="text-medium mt-2 text-[0.9rem] font-semibold  ">
          Des
        </h1>
        <input
          onChange={a.inputValue}
          name="Tem6_Des3"
          value={a.newState.Tem6_Des3}
          className="EditProfile my-4 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="A personalized trading platform using other platform's API. This platform allows the user to place multiple orders for multiple clients with a single click."
        />

        <h1 className="text-medium my-1 text-[0.9rem] font-semibold ">
          Start Date
        </h1>
        <input
          onChange={a.inputValue}
          name="Tem6_DateStart3"
          value={a.newState.Tem6_DateStart3}
          className="EditProfile my-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />

        <h1 className="text-medium my-1 text-[0.9rem] font-semibold ">
          End Date
        </h1>
        <input
          onChange={a.inputValue}
          name="Tem6_DateEnd3"
          value={a.newState.Tem6_DateEnd3}
          className="EditProfile mt-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />
        <span className="text-xs mb-2 text-gray-400 font-bold">
          Leave it blank to mark it as Present
        </span>
        {/* Third COntent End  */}
      </div>
      {/* Add And Delete BLockBUtton Start  */}

      {/* Add And Delete BLockBUtton End  */}
    </div>
  );
};

export default ProjectCom6;
