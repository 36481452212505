import React, { useContext, useState } from "react";
import stateContext from "../Context/State/StateContext";

const TemplateCom5 = () => {
  const a = useContext(stateContext);

  const [addSection, setAddSection] = useState([{ section : ""}]);
  const addButton = () => {
  setAddSection([...addSection,{section : ""}])
  };
  const sectionRemove = (index) =>{
    const section = [...addSection];
    section.splice(index, 1);
    setAddSection(section);
  }

  return (
    <>
      <div
      id="DownloadablePage"
       className="MainContainer m-1 pb-5 mx-9">
        <div className="top h-[20%] py-4 mx-auto w-[98%] bg-[#008fd5] flex  flex-row-reverse  border-b-[6px] border-black  items-center justify-around">
          <div
            className="profileName  flex flex-col space-y-
      h-[9rem] w-[50%] justify-center mx-5 "
          >
            <h1 className="text-[32px] relative tracking-[1px] font-bold text-[white]">
            {a.newState.ProfileName}
              <span
                onClick={() => {
                  a.updateState("profileName");
                }}
                className="mx-5
           px-1 text-sm absolute -right-2 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white"
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>
            <h2 className="font-normal text-[14px] text-[white]">
            {a.newState.JobRole}
              
            </h2>
            <h1 className="font-normal relative break-words my-5 h-[20%] text-[14px] text-[white]">
            {a.newState.ProfileInfo}
              <span
                onClick={() => {
                  a.updateState("about");
                }}
                className="mx-5
           px-1 text-sm absolute -right-20 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white"
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>
          </div>
          <div className="ProdfilePicture cursor-pointer bg-[white] my-8  w-[6rem] h-[6rem] rounded-full flex items-center justify-center sm:w-[8.5rem] sm:h-[8.5rem] xl:w-[8rem] xl:h-[8rem] text-[#008fd5]  ">
            <div className="file text-center space-y-1">
              <label for="files" className="">
                <i class="fa-solid fa-circle-arrow-up text-2xl" id="file"></i>
              </label>
              <p className="text-sm">Upload Photo</p>
              <input id="files" className="hidden" type="file" />
            </div>
          </div>
        </div>
        {/* BottomContainer Start  */}

        <div
          className="Bottom mx-2 rounded-sm  h-auto min-h-[80%] flex  justify-between 
  overflow-y-scroll overflow-x-scroll scroll-smooth scrollbar-hide "
        >
          <div
            className="left w-[40%] py-3 sm:w-[35%]   text-white {
        
    } "
          >
            <div className="mx-5 mt-4 ">
              {/* CONTENT TWO 1 START */}
              <div className="contentleft1 space-y-2 ">
            <h1 className="contactinfo text-[12px] text-[#008fd5] relative font-bold">
              CONTACT INFO
              <span
                onClick={() => {
                  a.updateState("contact");
                }}
                className={`mx-5
             px-1 text-sm absolute  -right-10 sm:right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             } `}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>

            <div className="contentleft  text-[black] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-[#008fd5]">
                <i class="fa-solid fa-location-dot "></i>{" "}
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <p>{a.newState.C_Location1}</p>
                <p>{a.newState.C_Location2}</p>
              </div>
            </div>

            <div className="contentleft  text-[black] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-[#008fd5]">
                <i class="fa-solid fa-envelope-open"></i>
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <p>{a.newState.C_Email}</p>
              </div>
            </div>

            <div className="contentleft text-[black] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-[#008fd5]">
                <i class="fa-solid fa-phone"></i>
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <p>{a.newState.C_Phone}</p>
              </div>
            </div>

            <div className="contentleft text-[black] flex justify-between items-center">
              <div className="left w-12 text-[10px] text-[#008fd5]">
                <i class="fa-solid fa-link"></i>
              </div>
              <div className="right w-4/5 font-normal text-[11px]">
                <p>{a.newState.C_Link}</p>
              </div>
            </div>
          </div>
              {/* CONTENT TWO 1 START */}

              {/* cONTENT ONE 2 START */}
              <div className="contentleft1 space-y-2 my-4 ">
                <div className="contentleft text-[#cbd3e0] flex justify-between items-center">
                  <div className="right w-4/5 font-normal text-[11px]">
                    <h1 className="text-[16px] text-[#008fd5] relative font-bold">
                      Education

                      <span
                 onClick={() => {
                   a.updateState("education");
                 }}
                 id="editbtn"
                 className={`mx-5
            px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
              a.RemoveIcons ? "hidden" : ""
            }`}
               >
                 <i class="fa-solid fa-pen text-xs"></i>
               </span>
                    </h1>
                  </div>
                </div>

                <div className="contentleft  text-[black] leading-5">
                  <div className="right w-4/5 text-[black] font-normal text-[11px]">
                  {a.newState.E_DateStart} -{a.newState.E_EndDate}{" "}
                {a.newState.E_Title3} {a.newState.E_Title2}
                  </div>
                </div>

                <div className="contentleft  text-[black] leading-5">
                  <div className="right w-4/5 text-[black] font-normal text-[11px]">
                  {a.newState.E_DateStart1} -{a.newState.E_EndDate2}{" "}
                {a.newState.E_Title5} {a.newState.E_Title4}
                  </div>
                </div>
              </div>
              {/* CONTENT ONE 2 START */}

              {/* Content three 3 Start  */}
              <div className="content1 space-y-2 my-3 text-[black] ">
                <h1 className="text-[#008fd5] text-[14px] relative font-bold">
                 Skills
                 <span
                onClick={() => {
                  a.updateState("skills");
                }}
                className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             }`}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
                </h1>
               <div className="item2  text-[11px] flex flex-col items-start justify-around space-y-3 ">
                  <span className="DotLevel  ">{a.newState.Skill1}</span>
                  <span className="DotLevel  ">{a.newState.Skill2}</span>
                  <span className="DotLevel  ">{a.newState.Skill3}</span>
                  <span className="DotLevel  ">{a.newState.Skill4}</span>
                  <span className="DotLevel  ">{a.newState.Skill5}</span>
                  <span className="DotLevel  ">{a.newState.Skill6}</span>
                </div>
              </div>
              {/* Content three 3 End  */}
            </div>
          </div>
          {/* Right Container Start  */}
          <div className="right  mx-2 py-3 mr-10 ml-5 flex flex-col ">
            <div className="contain  my-3 px-2">
              <div className="right w-4/5 font-normal text-[11px]">
                
          {addSection.map((index) => (
             <div key={index} className="content1 space-y-2 my-3 ">
             <h1 className="text-[#5da9d2] text-[12px] relative font-bold">
            Work Expriance
               <span
                 onClick={() => {
                   a.updateState("expriance");
                 }}
                 id="editbtn"
                 className={`mx-5
            px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
              a.RemoveIcons ? "hidden" : ""
            }`}
               >
                 <i class="fa-solid fa-pen text-xs"></i>
               </span>
              {addSection.length > 1 && <span
                 onClick={() => {
                  sectionRemove(index);
                 }}
              className={`delete mx-5
            px-1 text-sm absolute  right-5 -top-0 rounded-sm bg-[red] border border-white text-white ${
              a.RemoveIcons ? "hidden" : ""
            }`}
               ><i class="fa-solid fa-trash"></i>
               </span>}
             </h1>
             <h1 className="text-[#413f40] text-[12px] font-bold">
             {a.newState.Title2}
             </h1>
             <p className="text-[black] flex justify-between text-[11px] font-normal leading-4">

             {a.newState.Title3}
               <span className="text-[#008fd5]"> {a.newState.DateStart} - {a.newState.EndDate}</span>
             </p>
             <p className="text-[black] leading-4 text-[11px]">
               <li className="text-justify my-2 flex">
                 <li></li> <p> {a.newState.Point1}</p>
               </li>
               <li className="text-justify my-2 flex">
                 <li></li>
                 <p>{a.newState.Point2}</p>
               </li>
               <li className="text-justify my-2 flex">
                 <li></li>
                 <p>{a.newState.Point3}</p>
               </li>
             </p>
           </div>
          ))}
              </div>

              <div
            onClick={()=>{addButton("1")}}
            className={`ADDBUTTON p-2 flex rounded-md font-medium justify-center items-center text-[#8b24f4] border border-[#8b24f4] border-dotted cursor-pointer ${
              a.RemoveIcons ? "hidden" : ""
            } `}
          >
            <i class="fa-solid fa-plus mx-3"></i> Add Section
          </div>

              <div className="content1 space-y-1 my-3 ">
              <h1 className="text-[#5da9d2] text-[12px] font-bold relative">
              {a.newState.P_Title}
              <span
                onClick={() => {
                  a.updateState("projects");
                }}
                className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white  text-white ${
               a.RemoveIcons ? "hidden" : ""
             } `}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>
            <p className="text-[#413f40] text-[13px] font-normal leading-4">
              {a.newState.P_Title3}
            </p>
            <p className="text-[#7e7e7c] leading-4 text-[11px]">
              <li className="text-justify my-2 flex">
                <li></li> <p>{a.newState.P_Point1}</p>
              </li>
              <li className="text-justify my-2 flex">
                <li></li>
                <p>{a.newState.P_Point2}</p>
              </li>
            </p>
               
              </div>

            

              <div className="CONTENT2 my-3 ">
               
              <h1 className="text-[#5da9d2] my-3 text-[12px] relative font-bold">
              Interests
              <span
                onClick={() => {
                  a.updateState("interest");
                }}
                className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white  text-white ${
               a.RemoveIcons ? "hidden" : ""
             } `}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
            </h1>
            <div className="item2  text-[11px] flex items-center justify-around flex-wrap  space-x-5 space-y-3 ">
              <span></span>
              <span className="DotLevel  ">{a.newState.C4_Title1}</span>
              <span className="DotLevel  ">{a.newState.C4_Title2}</span>
              <span className="DotLevel  ">{a.newState.C4_Title3}</span>
              <span className="DotLevel  ">{a.newState.C4_Title4}</span>
              <span className="DotLevel  ">{a.newState.C4_Title5}</span>
            </div>
              </div>
            </div>
          </div>
          {/* Right Container End  */}
        </div>

        {/* Bottom Cointainer End  */}
      </div>
    </>
  );
};

export default TemplateCom5;
