import React, { useState } from 'react'

const Navbar = () => {
    let [show,setShow]=useState(false);
  return (
    <div className='w-full z-50 top-0 left-0 break-words '>
      <div className='md:flex items-center justify-between  py-6 md:px-10 px-7'>
        <a href="/">
      <div className='font-extrabold text-2xl cursor-pointer flex items-center
      text-[#8168FA]  drop-shadow-[1px_1px_1px_white] md:text-3xl '>
        Free Resume Builder
      </div>
      </a>
      
      <div className='text-3xl absolute right-8 top-6 cursor-pointer md:hidden'
       onClick={()=>setShow(!show)}>
      <ion-icon name={show ? 'close':'menu'}></ion-icon>
      </div>

      <ul className={`md:flex text-[#5c0aa7] md:mr-8 md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto  left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500  z-40 space-x-8 space-y-3 md:space-y-[-1rem] ease-in ${show ? 'top-20 ':'top-[-490px]'}`}>
          <li></li>
       <li className="navitems cursor-pointer"> <a to="/" className="a font-medium">Home </a> </li>
       <li className="navitems cursor-pointer"> <a to="/templates" className="a font-medium">Templates </a> </li>
       <li className="navitems cursor-pointer"> <a href="https://training.cwipedia.in/" target="blank" className="a font-medium">Training </a> </li>
       <li className="navitems cursor-pointer"> <a href="https://www.cwipedia.in/" target="blank" className="a font-medium">Blog </a> </li>
      </ul>
      </div>
    </div>
  )
}

export default Navbar