import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const TemplateCom6 = () => {
  const a = useContext(stateContext);

  return (
    <div id="DownloadablePage" className="MainContainer6   mx-5 py-3">
      <div className="Top Header text-center space-y-2 py-3 ">
        <h1 className="Heading  relative text-2xl font-bold  text-blue-500">
        {a.newState.ProfileName}
          <span
                onClick={() => {
                  a.updateState("profileName");
                }}
                className="mx-5
           px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white "
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
          </h1>
        <span className="des text-xs">{a.newState.JobRole}</span>
      </div>
      <div className="Bottom flex justify-between items-center py-2  ">
        <div className="left w-1/2   flex justify-center items-center pb-4">
          <div className="centerDiv min-h-[38rem] text-center w-3/4 border  border-black">
            <div className="contactDiv text-left pb-5 ">
              <h1 className="Contact relative text-center font-medium border-b-[1px] border-black">
                Contact
                <span
                    onClick={() => {
                      a.updateState("contact");
                    }}
                    className="mx-5
           px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white "
                  >
                    <i class="fa-solid fa-pen text-xs"></i>
                  </span>
              </h1>
              <p className="item1 text-xs text-bold mt-3 mb-2 mx-3">
                <span className="text-blue-500 mx-2">E:</span>
                <span className="">{a.newState.C_Email}</span>
              </p>
              <p className="item1 text-xs text-bold my-2 mx-3">
                <span className="text-blue-500 mx-2">P:</span>
                <span className="">{a.newState.C_Phone}</span>
              </p>
              <p className="item1 text-xs text-bold my-2 mx-3">
                <span className="text-blue-500 mx-2">W:</span>
                <span className="">{a.newState.C_Link}</span>
              </p>
            </div>

            <div className="contactDiv text-left pb-5 ">
              <h1 className="Contact relative text-center font-medium border-t-[1px] border-b-[1px] border-black">
                Skills
                <span
                onClick={() => {
                  a.updateState("skills");
                }}
                className="mx-5
           px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white "
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
              </h1>

              <div
                className="skillsProgress my-3 mx-2 space-y-2 justify-between flex flex-col   text-[#333332]
           "
              >
                <div className="item w-1/2">
                  <p className="text-[12px] font-medium ">{a.newState.Skill1}</p>
                </div>
                <div className="item2 w-1/2 flex items-center justify-start space-x-5 ">
                  {/* <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7]"></span>
                  <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span> */}
                  <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level1 === "1" ||
                a.newState.Level1 === "2" ||
                a.newState.Level1 === "3" ||
                a.newState.Level1 === "4" ||
                a.newState.Level1 === "5" ||
                a.newState.Level1 === "6"
                  ? "bg-[#0268d7]"
                  : "bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level1 === "2" ||
                a.newState.Level1 === "3" ||
                a.newState.Level1 === "4" ||
                a.newState.Level1 === "5" ||
                a.newState.Level1 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level1 === "3" ||
                a.newState.Level1 === "4" ||
                a.newState.Level1 === "5" ||
                a.newState.Level1 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level1 === "4" ||
                a.newState.Level1 === "5" ||
                a.newState.Level1 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level1 === "5" || a.newState.Level1 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${a.newState.Level1 === "6" ? "bg-[#0268d7]" : " bg-[#9ac3ef]"}`}
                ></span>
                </div>
              </div>

              <div
                className="skillsProgress my-3 space-y-2 justify-between flex flex-col mx-2 text-[#333332]
           "
              >
                <div className="item  w-1/2">
                  <p className="text-[12px] font-medium ">{a.newState.Skill2}</p>
                </div>
                <div className="item2 w-1/2 flex items-center justify-start space-x-5 ">
                  {/* <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span> */}
                    <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level2 === "1" ||
                a.newState.Level2 === "2" ||
                a.newState.Level2 === "3" ||
                a.newState.Level2 === "4" ||
                a.newState.Level2 === "5" ||
                a.newState.Level2 === "6"
                  ? "bg-[#0268d7]"
                  : "bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level2 === "2" ||
                a.newState.Level2 === "3" ||
                a.newState.Level2 === "4" ||
                a.newState.Level2 === "5" ||
                a.newState.Level2 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level2 === "3" ||
                a.newState.Level2 === "4" ||
                a.newState.Level2 === "5" ||
                a.newState.Level2 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level2 === "4" ||
                a.newState.Level2 === "5" ||
                a.newState.Level2 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level2 === "5" || a.newState.Level2 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${a.newState.Level2 === "6" ? "bg-[#0268d7]" : " bg-[#9ac3ef]"}`}
                ></span>

                </div>
              </div>

              <div
                className="skillsProgress my-3 space-y-2 justify-between flex flex-col mx-2   text-[#333332]
           "
              >
                <div className="item  w-1/2">
                  <p className="text-[12px] font-medium ">{a.newState.Skill3}</p>
                </div>
                <div className="item2 w-1/2 flex items-center justify-start space-x-5 ">
                  {/* <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7]"></span>
                  <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span> */}
                   <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level3 === "1" ||
                a.newState.Level3 === "2" ||
                a.newState.Level3 === "3" ||
                a.newState.Level3 === "4" ||
                a.newState.Level3 === "5" ||
                a.newState.Level3 === "6"
                  ? "bg-[#0268d7]"
                  : "bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level3 === "2" ||
                a.newState.Level3 === "3" ||
                a.newState.Level3 === "4" ||
                a.newState.Level3 === "5" ||
                a.newState.Level3 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level3 === "3" ||
                a.newState.Level3 === "4" ||
                a.newState.Level3 === "5" ||
                a.newState.Level3 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level3 === "4" ||
                a.newState.Level3 === "5" ||
                a.newState.Level3 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level3 === "5" || a.newState.Level3 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${a.newState.Level3 === "6" ? "bg-[#0268d7]" : " bg-[#9ac3ef]"}`}
                ></span>
                </div>
              </div>

              <div
                className="skillsProgress my-3 space-y-2 justify-between flex  flex-col mx-2  text-[#333332]
           "
              >
                <div className="item  w-1/2">
                  <p className="text-[12px] font-medium ">{a.newState.Skill4}</p>
                </div>
                <div className="item2 w-1/2 flex items-center justify-start space-x-5 ">
                  {/* <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7]"></span>
                  <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full bg-[#9ac3ef] "></span> */}
                     <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level4 === "1" ||
                a.newState.Level4 === "2" ||
                a.newState.Level4 === "3" ||
                a.newState.Level4 === "4" ||
                a.newState.Level4 === "5" ||
                a.newState.Level4 === "6"
                  ? "bg-[#0268d7]"
                  : "bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level4 === "2" ||
                a.newState.Level4 === "3" ||
                a.newState.Level4 === "4" ||
                a.newState.Level4 === "5" ||
                a.newState.Level4 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level4 === "3" ||
                a.newState.Level4 === "4" ||
                a.newState.Level4 === "5" ||
                a.newState.Level4 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level4 === "4" ||
                a.newState.Level4 === "5" ||
                a.newState.Level4 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level4 === "5" || a.newState.Level4 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${a.newState.Level4 === "6" ? "bg-[#0268d7]" : " bg-[#9ac3ef]"}`}
                ></span>
                </div>
              </div>

              <div
                className="skillsProgress my-3 space-y-2 justify-between flex flex-col mx-2  text-[#333332]
           "
              >
                <div className="item  w-1/2">
                  <p className="text-[12px] font-medium ">{a.newState.Skill5}</p>
                </div>
                <div className="item2 w-1/2 flex items-center justify-start space-x-5 ">
                  {/* <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full  bg-[#0268d7]"></span>
                  <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span>
                  <span className="DotLevel p-[0.20rem] rounded-full bg-[#0268d7] "></span> */}
                   <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level5 === "1" ||
                a.newState.Level5 === "2" ||
                a.newState.Level5 === "3" ||
                a.newState.Level5 === "4" ||
                a.newState.Level5 === "5" ||
                a.newState.Level5 === "6"
                  ? "bg-[#0268d7]"
                  : "bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level5 === "2" ||
                a.newState.Level5 === "3" ||
                a.newState.Level5 === "4" ||
                a.newState.Level5 === "5" ||
                a.newState.Level5 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level5 === "3" ||
                a.newState.Level5 === "4" ||
                a.newState.Level5 === "5" ||
                a.newState.Level5 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level5 === "4" ||
                a.newState.Level5 === "5" ||
                a.newState.Level5 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${
                a.newState.Level5 === "5" || a.newState.Level5 === "6"
                  ? "bg-[#0268d7]"
                  : " bg-[#9ac3ef]"
              }`}
                ></span>
                <span
                  className={`DotLevel p-[0.20rem] rounded-full 
              ${a.newState.Level5 === "6" ? "bg-[#0268d7]" : " bg-[#9ac3ef]"}`}
                ></span>
                </div>
              </div>
            </div>
            <div className="content1 space-y-2 my-3 text-[black] ">
              <h1 className=" text-black border-b-[1px] border-t-[1px] border-black text-[14px] relative font-bold">
                Other Skills
                <span
                onClick={() => {
                  a.updateState("interest");
                }}
                className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             }`}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
              </h1>
              <div className="item2 mx-2 text-[11px]  flex flex-col items-start justify-around space-y-3 ">
                <span className="DotLevel  ">{a.newState.C4_Title1}</span>
                <span className="DotLevel  ">{a.newState.C4_Content1}</span>
                <span className="DotLevel  ">{a.newState.C4_Title3}</span>
                <span className="DotLevel  ">{a.newState.C4_Title4}</span>
                <span className="DotLevel  ">{a.newState.C4_Title5}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="right w-1/2   pb-4" >
          <div className="centerDiv w-full  flex justify-center items-center">
            <div className="center text-center min-h-[38rem] border w-11/12  border-black">
            <h1 className="Contact relative text-center font-medium border-b-[1px] border-black">
                  Project's
                  <span
                onClick={() => {
                  a.updateState("projects3");
                }}
                className={`mx-5
             px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-white ${
               a.RemoveIcons ? "hidden" : ""
             }`}
              >
                <i class="fa-solid fa-pen text-xs"></i>
              </span>
                </h1>
              <div className="contactDiv w-3/4   mx-auto  text-center pb-5 ">
               
                  {/* // First content start */}
                <p className="mt-3 space-y-2">
                  <span className="font-medium text-[11px] pt-2 ">
                    {a.newState.Tem6_DateStart1} - {a.newState.Tem6_DateEnd1}
                  </span>
                  <p className="font-medium text-[11px]  ">
                  {a.newState.Tem6_Title1}
                  </p>
                  <span className="font-medium text-[11px]  ">
                  {a.newState.Tem6_Title01}
                  </span>
                </p>
                <p className="text-[10px] mt-2 ">
                {a.newState.Tem6_Des1}
                </p>
                  {/* // First content End */}
                {/* // Second content start */}
                <p className="mt-3 space-y-2">
                  <span className="font-medium text-[11px] pt-2 ">
                    {a.newState.Tem6_DateStart2} - {a.newState.Tem6_DateEnd2}
                  </span>
                  <p className="font-medium text-[11px]  ">
                  {a.newState.Tem6_Title2}
                  </p>
                  <span className="font-medium text-[11px]  ">
                  {a.newState.Tem6_Title02}
                  </span>
                </p>
                <p className="text-[10px] mt-2 ">
                {a.newState.Tem6_Des2}
                </p>
                {/* //second content end */}

                {/* // Third content start */}
                <p className="mt-3 space-y-2">
                  <span className="font-medium text-[11px] pt-2 ">
                    {a.newState.Tem6_DateStart3} - {a.newState.Tem6_DateEnd3}
                  </span>
                  <p className="font-medium text-[11px]  ">
                  {a.newState.Tem6_Title3}
                  </p>
                  <span className="font-medium text-[11px]  ">
                  {a.newState.Tem6_Title03}
                  </span>
                </p>
                <p className="text-[10px] mt-2 ">
                {a.newState.Tem6_Des3}
                </p>
                {/* //Third content end */}
              </div>
              <h1 className="Contact  relative text-center font-medium border-t-[1px] border-b-[1px] border-black">
                  Education
                   <span
                    onClick={() => {
                      a.updateState("education");
                    }}
                    className="mx-5
           px-1 text-sm absolute right-0 top-0 rounded-sm bg-[#5f0aa8] border border-white text-[white]"
                  >
                    <i class="fa-solid fa-pen text-xs"></i>
                  </span>
                </h1>
              <div className="contactDiv w-3/4  mx-auto  text-center ">
               
                  {/* // First content start */}
                <p className="mt-3 space-y-2">
                  <span className="font-medium text-[11px] pt-2 ">
                 {a.newState.E_DateStart} -{a.newState.E_EndDate}{" "}
                 
                  </span>
                  <span className="font-medium text-[11px]  ">
                  {a.newState.E_Title2}
                  </span>
                  <span className="font-medium text-[11px]  ">
                  {a.newState.E_Title3}
                  </span>
                </p>
                <p className="text-[10px] my-2  ">
                {a.newState.E_Point01}
                </p>
                  {/* // First content End */}
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateCom6;
