import React, { useState } from "react";
import stateContext from "./StateContext";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const ChangeState = (props) => {
  const [RemoveIcons, setRemoveIcons] = useState();
  const [newState, setNewState] = useState({
    // Profile Informaiton Start
    ProfileName: "Olivia Wilson",
    ProfileName2: "Prince Kumar Singh",
    JobRole: "Engeneer",
    // Profile Informaiton End

    // About Info Start
    ProfileInfo:
      "A Python developer with 5.8 years of experience in Django, Flask for Retail eCommerce, POS and Storage domain.",
    // About Info Start

    // work Expriance Start
    Title: "Work Expriance",
    Title2: "OzoneAI",
    Title3: "Software Enginner",
    DateStart: "APR 2022",
    EndDate: "DEC 2022",
    Point1:
      "Utilized PySpark to distribute data processing on large streaming datasets to improve ingestion and processing speed of that daat by 87%",
    Point2:
      "Build basic ETL that ingested transactional and event data from a web app with 10,000 daily active users that saved over $85,000 annually in external vendor costs",
    Point3:
      "Build basic ETL that ingested transactional and event data from a web app with 10,000 daily active users that saved over $85,000 annually in external vendor costs",
    // Second Content Start
    Sec_Title: "Work Expriance",
    Sec_Title2: "OzoneAI",
    Sec_Title3: "Software Enginner",
    Sec_DateStart: "APR 2022",
    Sec_EndDate: "DEC 2022",
    Sec_Point1:
      "Utilized PySpark to distribute data processing on large streaming datasets to improve ingestion and processing speed of that daat by 87%",
    Sec_Point2:
      "Build basic ETL that ingested transactional and event data from a web app with 10,000 daily active users that saved over $85,000 annually in external vendor costs",
    // Second Content End
    // Eork Expriance End

    // Component4 Expriance Start
    C4E_Point1: "Instructor and Contributor at  ",
    C4E_Point2: "Internshala ISP",
    // Component4 Expriance End

    //  Component 4 Certification start
    C4C_Point1: "Cyber Security Virtual Internship Program",
    C4C_Point2: "ANZ",
    C4C_Point3:
      "Gain experienced How investigation works used different Social Engineering attacks",
    C4C_Point4: "Goldman Sachs Engineering Virtual Program",
    C4C_Point5:
      "Worked on Kali Linux to decrypt hash codes, Tool Used: hashcat",
    C4C_Point6: "Certifications & Courses",
    C4C_Point7:
      "Python for Data Science, PHP, Python, Cyber Security, MongoDB.",
    //  Component 4 Certification End

    // Projects Start
    P_Title: "Projects",
    P_Title2: "Campus Events", // Not Available in TemplateCom1
    P_Title3: "Campus Event",
    P_DateStart: "APR 2022", // Not Available in TemplateCom1
    P_EndDate: "DEC 2022", // Not Available in TemplateCom1
    P_Point1:
      "Led the data ingestion efforts for our three person team which developed a real time tracker of campus events for universities in Pennsylvania",
    P_Point2:
      "Built web scraper in Python that got data from websites of campus groups then built an ETL which loaded data into Amazon Redshift",
    // Projects End

    // Project 2 Start
    P2_Point1:
      " Verify Badge Website (User can verify their certificate) (Technology used: ReactJs, Bootstrap5, Firebase)(published: verify.cwcipedia.in)",
    P2_Point2:
      "MSBTE Practice (it fetches Sample Question Paper PDF from Website, published on PlayStore)",
    P2_Point3:
      "Verify Badge Website (User can verify their certificate) (Technology used: ReactJs, Bootstrap5, Firebase)(published: verify.cwcipedia.in)",
    P2_Point4:
      "Basic Online Bus Reservation System (Used Java and Oracle Database)",
    // Project 2 Start

    // Eduacation Start
    E_Title: "Education",
    E_Title2: "University Of Pittsburgh",
    E_Title3: "B.S in Computer Science",
    E_Title4: "University Of Pittsburgh 2",
    E_Title5: " B.S in Computer Science 2",
    E_DateStart: "APR 2022",
    E_EndDate: "DEC 2022",
    E_DateStart1: "APR 2022",
    E_EndDate2: "DEC 2022",
    E_Point01:
      "Relevant courses: Data Structures, Algorithm Design, Database Management Systems, Computer Vision, Software Design Methodology",
    E_Point1: "Cumulative GPA: 3.7",
    E_Point2: "Concentration: Software Engineering",
    E_Point3:
      "Relevant courses: Data Structures, Algorithm Design, Database Management Systems, Computer Vision, Software Design Methodology",
    // Eduacaation End

    // Intership Sates Start Com7
    I_Title1: "University Of Pittsburgh",
    I_Title2: "B.S in Computer Science",
    I_Title3: "University Of Pittsburgh 2",
    I_Title4: " B.S in Computer Science 2",
    I_DateStart: "APR 2022",
    I_EndDate: "DEC 2022",
    I_DateStart1: "APR 2022",
    I_EndDate2: "DEC 2022",
    // Intership States Com7 End

    //  Certification Co7
    Cer_title1: "Introduction to MySQL",
    Cer_title3: "Basic Web Developer",
    Cer_title2: "CCP - AWSCCP",
    // Certification end Com7

    // Contact US Start
    C_Location1: "3205 Eden Drive, Glen Allen",
    C_Location2: "Virginia - 23060",
    C_Email: "JhonWick@email.net",
    C_Phone: "804-931-9418",
    C_Link: "github.com/oliwil ",
    //Contactus End

    // Award Recived start
    A_Title1: "Most Outstanding Employee of the Year, Pixelpoint Hive (2015)",
    A_Title2: "Best Mobile App Design, HGFZ Graduate Center (2014)",
    A_Title3: "Design Award, Cliffmoor College (2012)",
    // Award Recived End

    // Template6  Projects States Start
    Tem6_Title1: "Trading Platform",
    Tem6_Title01: "Node.js, Express, Firebase, React, WebSocket",
    Tem6_Des1:
      "This platform allows the user to place multiple orders for multiple clients with a single click.",
    Tem6_DateStart1: "Jun 2020",
    Tem6_DateEnd1: "Oct 2020",

    Tem6_Title2: "Trading Platform",
    Tem6_Title02: "Node.js, Express, Firebase, React, WebSocket",
    Tem6_Des2:
      "This platform allows the user to place multiple orders for multiple clients with a single click.",
    Tem6_DateStart2: "March 2020",
    Tem6_DateEnd2: "APR 2020",

    Tem6_Title3: "Trading Platform",
    Tem6_Title03: "Node.js, Express, Firebase, React, WebSocket",
    Tem6_Des3:
      "This platform allows the user to place multiple orders for multiple clients with a single click.",
    Tem6_DateStart3: "March 2020",
    Tem6_DateEnd3: "Nov 2020",

    // Template 6 Projects States End

    // Projects Component 9 Start
    P4_Title1: "Virtual Mine Lab Development",
    P4_Brief1:
      "Content was created for simulation to develop virtual lab which gives opportunity to learn experiments online.",
    P4_Title2: "Virtual Mine Lab Development",
    P4_Brief2:
      "Content was created for simulation to develop virtual lab which gives opportunity to learn experiments online.",
    P4_Title3: "Virtual Mine Lab Development",
    P4_Brief3:
      "Content was created for simulation to develop virtual lab which gives opportunity to learn experiments online.",
    // Projects Component 9 end

    // Course States Start
    Course1: "Introduction to MySQL",
    Course2: "Basic Web Development",
    // Course States End

    // Achivments States Start
    Achivement1: "Best student award in High School",
    Achivement2: "1st prize in General Knowledge competition",
    // Achivments States End

    // Activites States Start
    Activites1: "Organized quiz competition in Kalakriti",
    Activites2: "Presentations in SME events",
    // Activites States End

    // Hobies States Start
    Hobies1: "Swimming",
    Hobies2: "Playing Cricket",
    Hobies3: " Travelling",
    // Hobies States End

    // Por States Start
    Por_Title1: "Joint Secretary at SME",
    Por_Title2: "Associate Coordinator at Enginner Tech Fest",
    Por_Brief1:
      "Organizing and scheduling various SME events, actingas a link between participants from all over India and my college.",
    Por_Brief2:
      "Content was created for simulation to develop virtual lab which gives opportunity to learn experiments online.",
    // Por States end

    //  Training Com9 Start
    Traning_T1: "Underground Vocational Training, Kunustoria Colliery",
    Company_Name1: "Eastern Coalfield Limited",
    Training_Periad1: "May 2019",
    Traning_T2: "Underground Vocational Training, Kunustoria Colliery",
    Company_Name2: "Eastern Coalfield Limited",
    Training_Periad2: "May 2019",
    // Trainig Com 9 End

    // Skills Start
    Skill1: "MongoDB",
    Skill2: "HTML",
    Skill3: "JS",
    Skill4: "React JS",
    Skill5: "Node",
    Skill6: "Tailwind",
    Level1: "2",
    Level2: "4",
    Level3: "1",
    Level4: "4",
    Level5: "3",
    Level6: "1",
    // Skills End

    // Skills 2 Start for Com4
    C4_Title: "Languages",
    C4_Title1: "English",
    C4_Title2: "Lib/Frameworks",
    C4_Title3: "IDE/Editor ",
    C4_Title4: " Database ",
    C4_Title5: "OS",
    C4_Content1: "Python, java",
    C4_Content2: "ReactJs(Beginner level), Bootstrap",
    C4_Content3: "VS Code, Sublime Text, Jupyter Notebook",
    C4_Content4: "Oracle, MySQL, MongoDB(Beginner level)",
    C4_Content5: "Linux Distro(Kali, Ubuntu,Debian),Windows",
    // Skills 2 End for Com4

    // Work 2 Start for Com4
    C4W_Title: "Contributor:-",
    C4W_Title2: "Penetration Testing Intern:-",
    C4W_Title3: "VTF, California:- ",
    C4W_Link: "Badged",
    C4W_Content1: "GirlScript Winter of Contributing",
    C4W_Content2: "VTF, California",
    C4W_Content3: "The Sparks Foundation ",
    C4W_Point1:
      "Completed the following: Hacktify penetration hands-on Lab assignments and Networking Skills assignments.",
    C4W_Point2:
      "Completed the following: Hacktify penetration hands-on Lab assignments and Networking Skillsassignments.",
    C4W_Point3:
      "acquired cybersecurity skills, developed corporate codes and ethics, including time management and accountability.",
    C4W_Point4: "Learned and built project in Java with firebase auth",
    C4W_Point5: "Technology Used: Java, Firebase",
    C4W_Date1: "(Aug-21 to Dec 21)",
    C4W_Date2: "(Aug-21 to Dec 21)",
    C4W_Date3: "(Aug-21 to Dec 21)",
    // Work 2 End for Com4

    RemoveIcons: "",
  });

  // Downloade start
  const remove = (id) => {
    const page = document.getElementById(id);
    html2canvas(page).then((canvas) => {
      const ImgData = canvas.toDataURL("image/png");
      const imgHeight = 600;
      const imgWidth = 850;
      const pdf = new jsPDF("p", "pt", "a4");
      pdf.addImage(ImgData, "JPEG", 0, 0, imgHeight, imgWidth);
      pdf.save(`${newState.ProfileName}`);
    });
  };
  const downloadResume = (id) => {
    setRemoveIcons(true);
    setTimeout(() => {
      remove(id);
    }, 100);
    setTimeout(() => {
      setRemoveIcons(false);
    }, 1000);
  };
  // download End

  const [state, setState] = useState();
  const updateState = (change) => {
    setState(change);
    console.log("this is run");
  };

  const updateLevel = (id, level) => {
    setNewState(() => {
      switch (id) {
        case "Level1":
          return { ...newState, Level1: level };

        case "Level2":
          return { ...newState, Level2: level };

        case "Level3":
          return { ...newState, Level3: level };

        case "Level4":
          return { ...newState, Level4: level };

        case "Level5":
          return { ...newState, Level5: level };

        case "Level6":
          return { ...newState, Level6: level };

        default:
          break;
      }
    });
  };

  const inputValue = (e) => {
    setNewState(() => {
      setNewState({
        ...newState,
        [e.target.name]: e.target.value,
      });
      console.log("this is", newState.ProfileName);
    });
  };
  const reset = () => {
    setNewState({
      // Profile Informaiton Start
      ProfileName: "Olivia Wilson",
      ProfileName2: "Prince Kumar Singh",
      JobRole: "Engeneer",
      // Profile Informaiton End

      // About Info Start
      ProfileInfo:
        "A Python developer with 5.8 years of experience in Django, Flask for Retail eCommerce, POS and Storage domain.",
      // About Info Start

      // work Expriance Start
      Title: "Work Expriance",
      Title2: "OzoneAI",
      Title3: "Software Enginner",
      DateStart: "APR 2022",
      EndDate: "DEC 2022",
      Point1:
        "Utilized PySpark to distribute data processing on large streaming datasets to improve ingestion and processing speed of that daat by 87%",
      Point2:
        "Build basic ETL that ingested transactional and event data from a web app with 10,000 daily active users that saved over $85,000 annually in external vendor costs",
      Point3:
        "Build basic ETL that ingested transactional and event data from a web app with 10,000 daily active users that saved over $85,000 annually in external vendor costs",
      // Second Content Start
      Sec_Title: "Work Expriance",
      Sec_Title2: "OzoneAI",
      Sec_Title3: "Software Enginner",
      Sec_DateStart: "APR 2022",
      Sec_EndDate: "DEC 2022",
      Sec_Point1:
        "Utilized PySpark to distribute data processing on large streaming datasets to improve ingestion and processing speed of that daat by 87%",
      Sec_Point2:
        "Build basic ETL that ingested transactional and event data from a web app with 10,000 daily active users that saved over $85,000 annually in external vendor costs",
      // Second Content End
      // Eork Expriance End

      // Component4 Expriance Start
      C4E_Point1: "Instructor and Contributor at  ",
      C4E_Point2: "Internshala ISP",
      // Component4 Expriance End

      //  Component 4 Certification start
      C4C_Point1: "Cyber Security Virtual Internship Program",
      C4C_Point2: "ANZ",
      C4C_Point3:
        "Gain experienced How investigation works used different Social Engineering attacks",
      C4C_Point4: "Goldman Sachs Engineering Virtual Program",
      C4C_Point5:
        "Worked on Kali Linux to decrypt hash codes, Tool Used: hashcat",
      C4C_Point6: "Certifications & Courses",
      C4C_Point7:
        "Python for Data Science, PHP, Python, Cyber Security, MongoDB.",
      //  Component 4 Certification End

      // Projects Start
      P_Title: "Projects",
      P_Title2: "Campus Events", // Not Available in TemplateCom1
      P_Title3: "Campus Event",
      P_DateStart: "APR 2022", // Not Available in TemplateCom1
      P_EndDate: "DEC 2022", // Not Available in TemplateCom1
      P_Point1:
        "Led the data ingestion efforts for our three person team which developed a real time tracker of campus events for universities in Pennsylvania",
      P_Point2:
        "Built web scraper in Python that got data from websites of campus groups then built an ETL which loaded data into Amazon Redshift",
      // Projects End

      // Project 2 Start
      P2_Point1:
        " Verify Badge Website (User can verify their certificate) (Technology used: ReactJs, Bootstrap5, Firebase)(published: verify.cwcipedia.in)",
      P2_Point2:
        "MSBTE Practice (it fetches Sample Question Paper PDF from Website, published on PlayStore)",
      P2_Point3:
        "Verify Badge Website (User can verify their certificate) (Technology used: ReactJs, Bootstrap5, Firebase)(published: verify.cwcipedia.in)",
      P2_Point4:
        "Basic Online Bus Reservation System (Used Java and Oracle Database)",
      // Project 2 Start

      // Eduacation Start
      E_Title: "Education",
      E_Title2: "University Of Pittsburgh",
      E_Title3: "B.S in Computer Science",
      E_Title4: "University Of Pittsburgh 2",
      E_Title5: " B.S in Computer Science 2",
      E_DateStart: "APR 2022",
      E_EndDate: "DEC 2022",
      E_DateStart1: "APR 2022",
      E_EndDate2: "DEC 2022",
      E_Point01:
        "Relevant courses: Data Structures, Algorithm Design, Database Management Systems, Computer Vision, Software Design Methodology",
      E_Point1: "Cumulative GPA: 3.7",
      E_Point2: "Concentration: Software Engineering",
      E_Point3:
        "Relevant courses: Data Structures, Algorithm Design, Database Management Systems, Computer Vision, Software Design Methodology",
      // Eduacaation End

      // Intership Sates Start Com7
      I_Title1: "University Of Pittsburgh",
      I_Title2: "B.S in Computer Science",
      I_Title3: "University Of Pittsburgh 2",
      I_Title4: " B.S in Computer Science 2",
      I_DateStart: "APR 2022",
      I_EndDate: "DEC 2022",
      I_DateStart1: "APR 2022",
      I_EndDate2: "DEC 2022",
      // Intership States Com7 End

      //  Certification Co7
      Cer_title1: "Introduction to MySQL",
      Cer_title3: "Basic Web Developer",
      Cer_title2: "CCP - AWSCCP",
      // Certification end Com7

      // Contact US Start
      C_Location1: "3205 Eden Drive, Glen Allen",
      C_Location2: "Virginia - 23060",
      C_Email: "JhonWick@email.net",
      C_Phone: "804-931-9418",
      C_Link: "github.com/oliwil ",
      //Contactus End

      // Award Recived start
      A_Title1: "Most Outstanding Employee of the Year, Pixelpoint Hive (2015)",
      A_Title2: "Best Mobile App Design, HGFZ Graduate Center (2014)",
      A_Title3: "Design Award, Cliffmoor College (2012)",
      // Award Recived End

      // Template6  Projects States Start
      Tem6_Title1: "Trading Platform",
      Tem6_Title01: "Node.js, Express, Firebase, React, WebSocket",
      Tem6_Des1:
        "This platform allows the user to place multiple orders for multiple clients with a single click.",
      Tem6_DateStart1: "Jun 2020",
      Tem6_DateEnd1: "Oct 2020",

      Tem6_Title2: "Trading Platform",
      Tem6_Title02: "Node.js, Express, Firebase, React, WebSocket",
      Tem6_Des2:
        "This platform allows the user to place multiple orders for multiple clients with a single click.",
      Tem6_DateStart2: "March 2020",
      Tem6_DateEnd2: "APR 2020",

      Tem6_Title3: "Trading Platform",
      Tem6_Title03: "Node.js, Express, Firebase, React, WebSocket",
      Tem6_Des3:
        "This platform allows the user to place multiple orders for multiple clients with a single click.",
      Tem6_DateStart3: "March 2020",
      Tem6_DateEnd3: "Nov 2020",
      // Template 6 Projects States End

      // Projects Component 9 Start
      P4_Title1: "Virtual Mine Lab Development",
      P4_Brief1:
        "Content was created for simulation to develop virtual lab which gives opportunity to learn experiments online.",
      P4_Title2: "Virtual Mine Lab Development",
      P4_Brief2:
        "Content was created for simulation to develop virtual lab which gives opportunity to learn experiments online.",
      P4_Title3: "Virtual Mine Lab Development",
      P4_Brief3:
        "Content was created for simulation to develop virtual lab which gives opportunity to learn experiments online.",
      // Projects Component 9 end

      // Course States Start
      Course1: "Introduction to MySQL",
      Course2: "Basic Web Development",
      // Course States End

      // Achivments States Start
      Achivement1: "Best student award in High SchoolL",
      Achivement2: "1st prize in General Knowledge competition",
      // Achivments States End

      // Activites States Start
      Activites1: "Organized quiz competition in Kalakriti",
      Activites2: "Presentations in SME events",
      // Activites States End

      // Hobies States Start
      Hobies1: "Swimming",
      Hobies2: "Playing Cricket",
      Hobies3: " Travelling",
      // Hobies States End

      // Por States Start
      Por_Title1: "Joint Secretary at SME",
      Por_Title2: "Associate Coordinator at Enginner Tech Fest",
      Por_Brief1:
        "Organizing and scheduling various SME events, actingas a link between participants from all over India and my college.",
      Por_Brief2:
        "Content was created for simulation to develop virtual lab which gives opportunity to learn experiments online.",
      // Por States end

      //  Training Com9 Start
      Traning_T1: "Underground Vocational Training, Kunustoria Colliery",
      Company_Name1: "Eastern Coalfield Limited",
      Training_Periad1: "May 2019",
      Traning_T2: "Underground Vocational Training, Kunustoria Colliery",
      Company_Name2: "Eastern Coalfield Limited",
      Training_Periad2: "May 2019",
      // Trainig Com 9 End

      // Skills Start
      Skill1: "MongoDB",
      Skill2: "HTML",
      Skill3: "JS",
      Skill4: "React JS",
      Skill5: "Node",
      Skill6: "Tailwind",
      Level1: "2",
      Level2: "4",
      Level3: "1",
      Level4: "4",
      Level5: "3",
      Level6: "1",
      // Skills End

      // Skills 2 Start for Com4
      C4_Title: "Languages",
      C4_Title1: "English",
      C4_Title2: "Lib/Frameworks",
      C4_Title3: "IDE/Editor ",
      C4_Title4: " Database ",
      C4_Title5: "OS",
      C4_Content1: "Python, java",
      C4_Content2: "ReactJs(Beginner level), Bootstrap",
      C4_Content3: "VS Code, Sublime Text, Jupyter Notebook",
      C4_Content4: "Oracle, MySQL, MongoDB(Beginner level)",
      C4_Content5: "Linux Distro(Kali, Ubuntu,Debian),Windows",
      // Skills 2 End for Com4

      // Work 2 Start for Com4
      C4W_Title: "Contributor:-",
      C4W_Title2: "Penetration Testing Intern:-",
      C4W_Title3: "VTF, California:- ",
      C4W_Link: "Badged",
      C4W_Content1: "GirlScript Winter of Contributing",
      C4W_Content2: "VTF, California",
      C4W_Content3: "The Sparks Foundation ",
      C4W_Point1:
        "Completed the following: Hacktify penetration hands-on Lab assignments and Networking Skills assignments.",
      C4W_Point2:
        "Completed the following: Hacktify penetration hands-on Lab assignments and Networking Skillsassignments.",
      C4W_Point3:
        "acquired cybersecurity skills, developed corporate codes and ethics, including time management and accountability.",
      C4W_Point4: "Learned and built project in Java with firebase auth",
      C4W_Point5: "Technology Used: Java, Firebase",
      C4W_Date1: "(Aug-21 to Dec 21)",
      C4W_Date2: "(Aug-21 to Dec 21)",
      C4W_Date3: "(Aug-21 to Dec 21)",
      // Work 2 End for Com4
    });
  };

  return (
    <stateContext.Provider
      value={{
        state,
        updateLevel,
        downloadResume,
        newState,
        RemoveIcons,
        reset,
        updateState,
        inputValue,
      }}
    >
      {props.children}
    </stateContext.Provider>
  );
};

export default ChangeState;
