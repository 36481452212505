import React, { useContext, useState } from "react";
import re from "../img/re1.png";
import Template1 from "../pages/Template1";
import Template2 from "../pages/Template2";
import Template3 from "../pages/Template3";
import TemplateCom4 from "./TemplateCom4";
import TempComp4Skills from "../EditResume/TempComp4Skills";
import Profile from "../EditResume/Profile";
import stateContext from "../Context/State/StateContext";
import About from "../EditResume/About";
import Skills from "../EditResume/Skills";
import Interest from "../EditResume/Interest";
import Expriance from "../EditResume/Expriance";
import TempCom4EXpriance from "../EditResume/TempCom4EXpriance";
import Projects from "../EditResume/Projects";
import Education from "../EditResume/Education";
import Contact from "../EditResume/Contact";
import AwardRecived from "../EditResume/AwardRecived";
import TempCom4Project from "../EditResume/TempCom4Project";
import TempCom4Certification from "../EditResume/TempCom4Certification";
import TempCom4Work from "../EditResume/TempCom4Work";
import { useParams } from "react-router-dom";
import Templates from "../pages/Templates";
import TempNavbar from "./TempNavbar";
import TemplateCom5 from "./TemplateCom5";
import TemplateCom6 from "./TemplateCom6";
import TemplateCom7 from "./TemplateCom7";
import TemplateCom8 from "./TemplateCom8";
import TemplateCom9 from "./TemplateCom9";
import ProjectCom6 from "../EditResume/ProjectCom6";
import InternshipCom7 from "../EditResume/InternshipCom7";
import CertificationCom7 from "../EditResume/CertificationCom7";
import ProjectCom9 from "../EditResume/ProjectCom9";
import CourseCom9 from "../EditResume/CourseCom9";
import PorCom9 from "../EditResume/PorCom9";
import ActivitesCom9 from "../EditResume/ActivitesCom9";
import HobiesCom9 from "../EditResume/HobiesCom9";
import AchivementCom9 from "../EditResume/AchivementCom9";
import TrainingCom9 from "../EditResume/TrainingCom9";



const TempEdite = ({comp,size}) => {
  const a = useContext(stateContext)
  // const {comp} = useParams(); 
  console.log(comp);
  
  return (
    <>
    <TempNavbar/>
      <div className="MainContainer py-5  h-full md:flex justify-between ">
        <div className="Action my-5 mx-3 md:w-1/2 ">

          <h3 className="text-[blue] my-2 mx-5 md:mb-3">Action Window</h3>

          <div className={`ActionWindow rounded-lg border-[3px] w-[95%] mx-auto border-dotted border-gray-300 h-80 md:h-[28rem]   ${a.state ? 'border-none lg:h-fit  ' : ""}`}>

          <h1 className={`${a.state ? 'hidden ' : ""}  flex justify-center items-center h-full w-full`}> <span className="meg text-gray-400 text-center font-semibold text-2xl w-1/2">Click edit button of any block in the template.</span> </h1>

             { `${a.state}` === "profileName" && <Profile/>}
             { `${a.state}` === "contact" && <Contact/>}
             { `${a.state}` === "award" && <AwardRecived/>}
             { `${a.state}` === "about" && <About/>}
             { `${a.state}` === "expriance" && <Expriance/>}
             { `${a.state}` === "expriance2" && <TempCom4EXpriance/>}
             { `${a.state}` === "projects" && <Projects/>}
             { `${a.state}` === "Certification" && <TempCom4Certification/>}             
             { `${a.state}` === "projects2" && <TempCom4Project/>}
             { `${a.state}` === "Work1" && <TempCom4Work/>}
             { `${a.state}` === "skills" && <Skills/>}
             { `${a.state}` === "skillsCom4" && <TempComp4Skills/>}
             { `${a.state}` === "interest" && <Interest/>}
             { `${a.state}` === "education" && <Education/>}
             { `${a.state}` === "projects3" && <ProjectCom6/>}
             { `${a.state}` === "internship" && <InternshipCom7/>}
             { `${a.state}` === "certification" && <CertificationCom7/>}
             { `${a.state}` === "projects4" && <ProjectCom9/>}
             { `${a.state}` === "course" && <CourseCom9/>}
             { `${a.state}` === "por" && <PorCom9/>}
             { `${a.state}` === "activites" && <ActivitesCom9/>}
             { `${a.state}` === "hobies" && <HobiesCom9/>}
             { `${a.state}` === "achivement" && <AchivementCom9/>}
             { `${a.state}` === "training" && <TrainingCom9/>}





          </div>
        </div>
        <div className={`templatesDiv mx-2 md:w-2/3 `}> 
        {/* // Width change for Comp 4  */}
          <div className="top my-5 text-[blue] flex justify-between items-center">
            <h3 className="head1">Templates View</h3>
            <h3 className="head2">No. of Pages:1</h3>
          </div>
          <div className="Templates  border  rounded-lg mr-3 shadow-xl">
            {/* <img src={re} alt="" className="resume w-full h-full" /> */}
            { comp === "templates1" && <Template1/>}
            { comp === "templates2" && <Template2/>}
            { comp === "templates3" && <Template3/>}
            { comp === "templates4" && <TemplateCom4/>}
            { comp === "templates5" && <TemplateCom5/>}
            { comp === "templates6" && <TemplateCom6/>}
            { comp === "templates7" && <TemplateCom7/>}
            { comp === "templates8" && <TemplateCom8/>}
            { comp === "templates9" && <TemplateCom9/>}



          </div>
        </div>
      </div>
    </>
  );
};

export default TempEdite;
