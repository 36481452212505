import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const InternshipCom7 = () => {
  const a = useContext(stateContext);

  return (
    <div className="ProfileName m-5  h-full bg-white w-[90%] max-h-[46.5rem] overflow-y-scroll  shadow-xl py-7">
      <div className="center m-5  ">
        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">
          Organisation / University / Project Name
        </h1>
        <input
          onChange={a.inputValue}
          name="I_Title1"
          value={a.newState.I_Title1}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />
        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">
          Role / Responsibility / Course
        </h1>
        <input
          onChange={a.inputValue}
          name="I_Title2"
          value={a.newState.I_Title2}
          className="EditProfile my-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />
        <h1 className="text-medium my-1 text-[0.9rem] font-semibold ">
          Start Date
        </h1>
        <input
          onChange={a.inputValue}
          name="I_DateStart"
          value={a.newState.I_DateStart}
          className="EditProfile my-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="2019"
        />
        <h1 className="text-medium my-1 text-[0.9rem] font-semibold ">
          End Date
        </h1>
        <input
          onChange={a.inputValue}
          name="I_EndDate"
          value={a.newState.I_EndDate}
          className="EditProfile mt-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="2022"
        />
        <span className="text-xs mb-2 text-gray-400 font-bold">
          Leave it blank to mark it as Present
        </span>{" "}
        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">
          Organisation / University / Project Name
        </h1>
        <input
          onChange={a.inputValue}
          name="I_Title3"
          value={a.newState.I_Title3}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
           placeholder="ML Enginner"
        />
        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">
          Role / Responsibility / Course
        </h1>
        <input
          onChange={a.inputValue}
          name="I_Title4"
          value={a.newState.I_Title4}
          className="EditProfile my-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />
        <h1 className="text-medium my-1 text-[0.9rem] font-semibold ">
          Start Date
        </h1>
        <input
          onChange={a.inputValue}
          name="I_DateStart1"
          value={a.newState.I_DateStart1}
          className="EditProfile my-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="2020"
        />
        <h1 className="text-medium my-1 text-[0.9rem] font-semibold ">
          End Date
        </h1>
        <input
          onChange={a.inputValue}
          name="I_EndDate2"
          value={a.newState.I_EndDate2}
          className="EditProfile mt-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="2022"
        />
        <span className="text-xs mb-2 text-gray-400 font-bold">
          Leave it blank to mark it as Present
        </span>{" "}
      </div>
    </div>
  );
};

export default InternshipCom7;
