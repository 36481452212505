import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const AwardRecived = () => {
  const a = useContext(stateContext);

  return (
    <div className="ProfileName m-5 h-full bg-white w-[90%] shadow-xl py-7">
      <div className="center m-5  ">
        <h1 className="text-medium  text-[0.9rem] font-semibold  ">Award 1</h1>
        <input
          onChange={a.inputValue}
          name="A_Title1"
          value={a.newState.A_Title1}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Olivia Wilson"
        />
        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">
          Award 2
        </h1>
        <input
          onChange={a.inputValue}
          name="A_Title2"
          value={a.newState.A_Title2}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="ML Enginner"
        />

        <h1 className="text-medium  text-[0.9rem] font-semibold  ">Award 3</h1>
        <input
          onChange={a.inputValue}
          name="A_Title3"
          value={a.newState.A_Title3}
          className="EditProfile my-2 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Olivia Wilson"
        />
      </div>
      <div className="btn flex justify-center items-center p-2">
        <button
          onClick={() => {
            a.updateState("");
          }}
          className="discardbtn text-[#9c50f6] font-semibold "
        >
          Discard
        </button>
      </div>
    </div>
  );
};

export default AwardRecived;
