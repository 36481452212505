import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const About = () => {
  const a = useContext(stateContext);

  return (
    <>
      <div className="ProfileName m-5 h-full rounded-lg w-[90%]  shadow-xl py-7">
        <div className="center m-5 ">
          <h1 className="text-medium  text-[0.9rem] font-semibold  ">
            Personal Profile
          </h1>
          <textarea
            onChange={a.inputValue}
            name="ProfileInfo"
            value={a.newState.ProfileInfo}
            className="EditProfile my-4 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
            type="text"
            placeholder="Olivia Wilson"
          />
        </div>
        <div className="btn flex justify-center items-center p-2">
          <button
            onClick={() => {
              a.updateState("");
            }}
            className="discardbtn text-[#9c50f6] font-semibold "
          >
            Discard
          </button>
        </div>
      </div>
    </>
  );
};

export default About;
