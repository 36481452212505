import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const TempCom4EXpriance = () => {
  const a = useContext(stateContext);

  return (
    <div className="Maincontainerr">
      <h1 className="text-medium  text-[0.9rem] font-semibold  ">Point1</h1>
      <textarea
        onChange={a.inputValue}
        name="C4E_Point1"
        value={a.newState.C4E_Point1}
        className="EditProfile my-4 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
        type="text"
      />
      <h1 className="text-medium  text-[0.9rem] font-semibold  ">Point2</h1>
      <textarea
        onChange={a.inputValue}
        name="C4E_Point2"
        value={a.newState.C4E_Point2}
        className="EditProfile my-4 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
        type="text"
      />
    </div>
  );
};

export default TempCom4EXpriance;
