import React from 'react'
import TemplateCom1 from '../Component/TemplateCom1'

const Template1 = () => {
  return (
    <>
    <TemplateCom1/>
    </>
  )
}

export default Template1