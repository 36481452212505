import React, { useContext } from "react";
import stateContext from "../Context/State/StateContext";

const TrainingCom9 = () => {
  const a = useContext(stateContext);

  return (
    <div className="ProfileName m-5 h-full bg-white w-[90%] max-h-[46.5rem] overflow-y-scroll  shadow-xl py-7">
      <div className="center m-5  ">
        {/* Content 1 Start  */}

        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">Title</h1>
        <input
          onChange={a.inputValue}
          name="Traning_T1"
          value={a.newState.Traning_T1}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Underground Vocational Training, Kunustoria Colliery"
        />

        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">
          Company
        </h1>
        <input
          onChange={a.inputValue}
          name="Company_Name1"
          value={a.newState.Company_Name1}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Eastern Coalfield Limited"
        />

        <h1 className="text-medium mt-2 text-[0.9rem] font-semibold  ">
          Training Period
        </h1>
        <input
          onChange={a.inputValue}
          name="Training_Periad1"
          value={a.newState.Training_Periad1}
          className="EditProfile my-4 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="A personalized trading platform using other platform's API. This platform allows the user to place multiple orders for multiple clients with a single click."
        />

        <span className="text-xs mb-2 text-gray-400 font-bold">
          Leave it blank to mark it as Present
        </span>
        {/* Content 1 End  */}

        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">Title</h1>
        <input
          onChange={a.inputValue}
          name="Traning_T2"
          value={a.newState.Traning_T2}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Underground Vocational Training, Kunustoria Colliery"
        />

        <h1 className="text-medium my-2 text-[0.9rem] font-semibold ">
          Company
        </h1>
        <input
          onChange={a.inputValue}
          name="Company_Name2"
          value={a.newState.Company_Name2}
          className="EditProfile my-1 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="Eastern Coalfield Limited"
        />

        <h1 className="text-medium mt-2 text-[0.9rem] font-semibold  ">
          Training Period
        </h1>
        <input
          onChange={a.inputValue}
          name="Training_Periad2"
          value={a.newState.Training_Periad2}
          className="EditProfile my-4 mb-2 placeholder:text-[#9c50f6] text-[#9c50f6] outline-none border-b border-[#9c50f6] py-2 px-1 w-full font-semibold "
          type="text"
          placeholder="A personalized trading platform using other platform's API. This platform allows the user to place multiple orders for multiple clients with a single click."
        />

        <span className="text-xs mb-2 text-gray-400 font-bold">
          Leave it blank to mark it as Present
        </span>
        {/* Second Content Start  */}
      </div>
      {/* Add And Delete BLockBUtton Start  */}

      {/* Add And Delete BLockBUtton End  */}
    </div>
  );
};

export default TrainingCom9;
